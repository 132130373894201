import React , { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import axios from 'axios';

const View = () => {
    const alert = useAlert();
    const [content, setContent] = useState('');

    useEffect(() => {
        getData();
    }, []);

    // logo
    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'form_home'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setContent(response.data.value);

        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return <div id='slider-cw'>
        <div className="col-5 p-2 b-1 border border-danger rounded bg-light"> 
            <label htmlFor="inputAddress" className="form-label">Nhập vào nội dung</label>
            <textarea type='text' rows={7} className="form-control mt-1" defaultValue={content} onChange={(e) => setContent(e.target.value)} placeholder=""></textarea>
        </div>
        <button type='button' className='mt-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'form_home', value : content})}>Lưu</button>
                               
    </div>
}
export default View;
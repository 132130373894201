const AlertTemplate = ({options, message, close }) => (
    <div hove className={'lobibox-notify animated-fast fadeInDown notify-mini lobibox-notify-'+options.type} style={{maxHeight: '400px', minWidth: '400px'}}>
      <div className="lobibox-notify-icon-wrapper">
        <div className="lobibox-notify-icon">
            <div>
              <div className="icon-el">
                {options.type === 'info' && <i className="bx bx-info-circle font-size-alert"></i>}
                {options.type === 'success' && <i className="bx bx-smile font-size-alert"></i>}
                {options.type === 'error' && <i className="bx bx-tired font-size-alert"></i>}
              </div>
            </div>
        </div>
      </div>
      <div className="lobibox-notify-body">
        <div className="lobibox-notify-msg">{message}</div>
      </div>
      {/* <button className="btn-close" onClick={close}>×</button> */}
  </div>
);

export default AlertTemplate;
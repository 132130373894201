import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
// import validator from 'validator';
import UploadButton from '../media/upload/UploadButton';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useAlert } from 'react-alert';

const View = () => {
    const alert = useAlert();
    const { id } = useParams();
    const [termData, setTermData] = useState([]);
    const [catPost, setCatPost] = useState([]);
    const [image, setImage] = useState('');
    const [order, setOrder] = useState('');
    const [nameAdd, setNameAdd] = useState('');
    const [slugAdd, setSlugAdd] = useState('');
    const [parentIDAdd, setParentIDAdd] = useState(0);
    const [typeAdd, setTypeAdd] = useState('');
    const [isActivedAdd, setIsActivedAdd] = useState(1);

    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/category/detail', {
            id: id,
            is_actived : 1,
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTermData(response.data.term);
            setNameAdd(response.data.data.name_school_category);
            setSlugAdd(response.data.data.slug_school_category);
            setTypeAdd(response.data.data.type);
            setIsActivedAdd(response.data.data.is_actived);
            setImage(response.data.data.thumbnails);
            setOrder(response.data.data.order);
            setCatPost(response.data.cat_post);
        });
    }
   
    const updateData = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/category/edit', {
            name_category : nameAdd,
            slug_category : slugAdd,
            type : typeAdd,
            image : image,
            id : id,
            order : order,
            is_actived : isActivedAdd,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData();
            return alert.success(response.data.message);
        });
    } 
    
    const SelectOptionFull = (props) => {
        const content = props.catPost.map((d, i) => 
            <option key={i} value={d.id}>{d.name_school_category}</option>
        );

        return content ? content : <option value=''>Đang tải ...</option>;
    }

    const SelectOption = (props) => {
        const content = Object.keys(props.termData).map((t, i) =>
            <option value={t}>{termData[t]}</option>
        );

        return content;
    }

    useEffect(() => {
        getData();
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to={'/category/view'}>Danh mục</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {nameAdd}
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-12'>
                            <h6 className="mb-0 text-uppercase">Thêm danh mục</h6>
                        </div>
                    </div>
                    <hr/>
                    <div className='row'>

                    <div className="card">
                        <div className="card-body">

                            <div className='col-md-4'>
                                
                                <div className='p-4 border rounded'>
                                    <form method='POST' id="filter-form">
                                        <div className="form-group input-group-sm">
                                            <label htmlFor="usr">Tên danh mục (*): <b className="text-danger"></b></label>
                                            <input onChange={(e) => {setNameAdd(e.target.value);}} defaultValue={nameAdd} type="text" className="form-control"/>
                                        </div>
                                        <div className="form-group input-group-sm">
                                            <label htmlFor="pwd">Đường dẫn: <b className="text-danger"></b></label>
                                            <input onChange={(e) => setSlugAdd(e.target.value)} defaultValue={slugAdd} type="text" className="form-control"/>
                                        </div>
                                        <div className="form-group input-group-sm">
                                            <label htmlFor="pwd">Nhóm  (*): <b className="text-danger"></b></label>
                                            <select className="form-control" value={typeAdd} onChange={(e) => setTypeAdd(e.target.value)}>
                                                <option value={''}>== Chọn nhóm danh mục ==</option>
                                                <SelectOption termData={termData}/>
                                            </select>
                                        </div>
                                        {
                                            (typeAdd === 'post') ?
                                            <div className="form-group input-group-sm">
                                                <label htmlFor="pwd">Danh mục cha: <b className="text-danger"></b></label>
                                                <select className="form-control" value={parentIDAdd} onChange={(e) => setParentIDAdd(e.target.value)}>
                                                    <option value={''}>== Chọn loại danh mục cha ==</option>
                                                    <SelectOptionFull catPost={catPost}/>
                                                </select>
                                            </div>
                                            : null
                                        }
                                        
                                        <div className="form-group input-group-sm">
                                            <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                            <select className="form-control" value={isActivedAdd} onChange={(e) => setIsActivedAdd(e.target.value)}>
                                                <option value={''}>== Trạng thái ==</option>
                                                <option value={0}>Đã ẩn</option>
                                                <option value={1}>Đang hoạt động</option>
                                            </select>
                                        </div>
                                        <div className="form-group input-group-sm m-1">
                                            {image && <UploadButton idName="image-category" path='assets/images/svg' images={[image]} title='Chọn hình' value={setImage} /> }
                                        </div>
                                        <hr/>
                                        <button type="button" onClick={() => {updateData()}} className='btn btn-success'>Lưu</button>
                                    </form>
                                </div>
                                
                            </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default View;
import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';

function View() {
    const alert = useAlert();
    const [id, setId] = useState('');
    const [lastName , setLastName] = useState('');
    const [idDelete , setIdDelete] = useState('');
    const [firstName , setFirstName] = useState('');
    const [userName , setUserName] = useState('');
    const [userNameSearch , setUserNameSearch] = useState('');
    const [emailSearch , setEmailSearch] = useState('');
    const [email , setEmail] = useState('');
    const [phone , setPhone] = useState('');
    const [phoneSearch , setPhoneSearch] = useState('');
    const [password , setPassword] = useState('');
    const [confirmPassword , setConfirmPassword] = useState('');
    const [users , setUsers] = useState([]);
    const [limit] = useState(25);
    const [countData, setCountData] = useState(1);
    const [page, setPage] = useState(1);

    const onClickClose = () => {
        setId('');
        setLastName('');
        setFirstName('');
        setUserName('');
        setEmail('');
        setPassword('');
    }

    function getUsers(jsonParams) {
        axios.post(localStorage.getItem('url_api')+'api/auth/user/view', jsonParams,
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setUsers(response.data.data);
            setCountData(response.data.count);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const handleDelete = (event) => {
        axios.get(localStorage.getItem('url_api')+'api/auth/setting/user_create_remove/'+idDelete,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            onClickClose();
            getUsers();
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const HanldRegister = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/register',
        {
            "email" : email,
            "user_name" : userName,
            "phone" : phone,
            "first_name" : firstName,
            "last_name" : lastName,
            "password" : password,
            "confirm_password" : confirmPassword
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            onClickClose();
            getUsers({ 'email' : emailSearch, 'user_name' : userNameSearch, 'phone' : phoneSearch,'limit' : limit, 'page' : page});
            return alert.success(response.data.message);
        })
        .catch(result => { 
            if (result['response']['data'].constructor === String) {
                var data = JSON.parse(result['response']['data']);
            }

            for (const [key, value] of Object.entries(data)) {
                alert.info(value);
            }
        });
    }

    useEffect(() => {
        getUsers({
            'limit' : limit,
            'page' : page
        });
    }, []);

    const Loading = (props) => {
        const content = props.users.map((user, index) =>
            <tr key={index}>
                <td className="text-center align-middle">{index+1}</td>
                <td className='align-middle'>{user.full_name}</td>
                <td className='align-middle'>{user.user_name}</td>
                <td className='align-middle'>{user.email}</td>
                <td className="text-center align-middle">{user.phone}</td>
                <td className="text-center align-middle">{user.created_at}</td>
                <td className="text-center align-middle font-22"><i className={ user.is_actived === 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                <td  className="text-center align-middle">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={'/user/user-profile/'+ user.id} className="btn btn-warning ml-1 btn-sm">
                            <i className="lni lni-eye  text-white"></i>
                        </Link>
                        <button type="button" onClick={() => setIdDelete(user.id)} className="btn btn-primary ml-1 btn-sm " title="Xóa phân quyền" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );
        return (
            <tbody>
                {content}
            </tbody>
        );
    }

    return users.length > 0 ? <div className="page-content">
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="ps-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Tài khoản</li>
                </ol>
            </nav>
        </div>
        <div className="ms-auto">
            <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalFilter">
            <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button>
            <button type="button" className="mx-1 btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#myModalAdd">
            <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</button>
        </div>
    </div>
        <div className="row">
            <div className="col-xl-12 mx-auto">
                <h6 className="mb-0 text-uppercase">Tài khoản</h6>
                <hr/>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle">STT</th>
                                        <th className="text-center align-middle">Họ và tên</th>
                                        <th className="text-center align-middle">Tài khoản</th>
                                        <th className="text-center align-middle">Email</th>
                                        <th className="text-center align-middle">Điện thoại</th>
                                        <th className="text-center align-middle">Ngày tạo</th>
                                        <th className="text-center align-middle font-22">
                                            <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                            <i className='text-warning bx bx-checkbox'></i>
                                        </th>
                                        <th className="text-center align-middle">Cập nhật</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th className="text-center align-middle">STT</th>
                                        <th className="text-center align-middle">Họ và tên</th>
                                        <th className="text-center align-middle">Tài khoản</th>
                                        <th className="text-center align-middle">Email</th>
                                        <th className="text-center align-middle">Điện thoại</th>
                                        <th className="text-center align-middle">Ngày tạo</th>
                                        <th className="text-center align-middle font-22">
                                            <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                            <i className='text-warning bx bx-checkbox'></i>
                                        </th>
                                        <th className="text-center align-middle">Cập nhật</th>
                                    </tr>
                                </tfoot>
                                
                                <Loading users={users}/>
                            </table>

                            <PaginationControl
                                page={page}
                                between={4}
                                total={countData}
                                limit={limit}
                                changePage={(page) => {
                                    setPage(page); 
                                    getUsers({ 'email' : emailSearch, 'user_name' : userNameSearch, 'phone' : phoneSearch,'limit' : limit, 'page' : page});
                                    window.scrollTo(0,0);
                                }}
                                ellipsis={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="myModalAdd" data-bs-backdrop="static">
            <div className="modal-dialog modal-md  modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Thêm tài khoản</h4>
                        <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form method='POST'>
                            <div className="form-group input-group-sm row">
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="usr">Họ: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="pwd">Tên: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="usr">Tài khoản: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setUserName(e.target.value)} defaultValue={userName} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="pwd">Điện thoại: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setPhone(e.target.value)} defaultValue={phone} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="pwd">Email: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setEmail(e.target.value)} defaultValue={email} type="text" className="form-control"/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="pwd">Mật khẩu: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setPassword(e.target.value)} defaultValue={password} type="password" className="form-control"/>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="pwd">Mật khẩu: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setConfirmPassword(e.target.value)} defaultValue={confirmPassword} type="password" className="form-control"/>
                                </div>

                            </div>
                            <div className="text-center mt-3">
                                <button onClick={() => HanldRegister()} data-bs-dismiss="modal" type="button" className="btn btn-sm btn-primary">Thêm tài khoản</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

        <div className="modal fade" id="modalFilter" data-bs-backdrop="static">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Lọc nội dung</h4>
                        <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <input type="text" onChange={(e) => setUserNameSearch(e.target.value)} defaultValue={userNameSearch} className="form-control form-control-sm" placeholder="Tài khoản" name="search"/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <input type="text" onChange={(e) => setEmailSearch(e.target.value)} defaultValue={emailSearch} className="form-control form-control-sm" placeholder="Email" name="search"/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <input type="text" onChange={(e) => setPhoneSearch(e.target.value)} defaultValue={phoneSearch} className="form-control form-control-sm" placeholder="Số điện thoại" name="search"/>
                                </div>
                                <div className="col-md-12 mb-2 text-center">
                                    <button type="button" onClick={() => getUsers({ 'email' : emailSearch, 'user_name' : userNameSearch, 'phone' : phoneSearch,'limit' : limit, 'page' : page})} className="btn btn-success btn-sm btn-icon-split">
                                        <span className="icon text-white-50">
                                            <i className="bx text-white bx-search"></i>
                                        </span>
                                        <span className="text">Tìm</span>    
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

        <div className="modal fade" id="myModalRemove">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Xóa</h4>
                    <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                </div>

                <div className="modal-body">
                    <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> tài khoản này không ?</p>
                </div>

                <div className="modal-footer">
                    <button type="button" onClick={() => onClickClose()} className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                    <button type="button" onClick={() => handleDelete()} className="btn btn-primary" data-bs-dismiss="modal">Xóa</button>
                </div>

                </div>
            </div>
        </div>
    </div>: <></>
}
export default View;
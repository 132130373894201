import { Link } from "react-router-dom";

function View() {
    return <div className="error-404 d-flex align-items-center justify-content-center">
        <div className="container">
            <div className="card">
                <div className="row g-0">
                    <div className="col col-xl-5">
                        <div className="card-body p-4">
                            <h1 className="display-1"><span className="text-primary">4</span><span className="text-danger">0</span><span className="text-success">4</span></h1>
                            <h3 className="font-weight-bold ">Không tìm thấy trang !</h3>
                            <p>Bạn có yêu cầu về dịch vụ hoặc thắc mắc vấn đề gì cần liên hệ đến đội ngũ hổ trợ của chúng tôi</p>
                            <div className="mt-5"> 
                                <Link to={'/'} className="btn btn-primary btn-lg px-md-5 radius-30">Dashboard</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <img src={require('../../../assets/images/404.png')} className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default View;
import React , { useState, useEffect } from 'react';
import './Media.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { useAlert } from 'react-alert';

const UploadButton = (params) => {
    const alert = useAlert();
    const [multiple, setMultiple] = useState(params.multiple? true : false);
    const [fileData, setFileData] = useState([]);
    const [page, setPage] = useState(1);
    const [linkPushImage, setLinkPushImage] = useState(params.images ? params.images : []);
    const [idName, setIdName] = useState(params.idName ? params.idName : '');
    const [path, setPath] = useState(params.path ? params.path : '');

    const getData = async (intPage) => {

        let data = new FormData();
        data.append('path', path);
        data.append('page', intPage);

        axios.post(localStorage.getItem('url_api')+'api/auth/media/view', data,
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            intPage === 1 && setFileData(response.data.images);
            intPage > 1 && setFileData(fileData.concat(response.data.images));
        });

    } 
    
    const handleChange = (e) => {

        Object.keys(e.target.files).map((d, i) => {
            let file = e.target.files[i];
            if (file) {
                let data = new FormData();
                data.append('file', file);
                data.append('path', path);

                axios.post(localStorage.getItem('url_api')+'api/auth/media/upload', data,
                {
                    headers: {
                    'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                    'Content-Type': 'application/json'
                    }
                })
                .then(function (response) {
                    if (response.constructor === String) {
                        response = JSON.parse(response);
                    }

                    setPage(1);
                    getData(1);
                    return alert.success(response.data.message);
                });
            }
        });

    }

    const getLinkImage = (e) => {
        if(multiple === false) {
            setLinkPushImage([e]);
        } else {
            
            return linkPushImage[0] !== '' ? setLinkPushImage(linkPushImage.concat(e)) : setLinkPushImage([e]);
            
        }
    } 

    const AllFileData = (props) => {
        
        const content = Object.keys(props.fileData).map((d, i) =>
            <div className="col-2 mb-1 custom-image-col" key={i}>
                <a onClick={() => {getLinkImage(props.fileData[i])}} className="btn-link">
                    <img src={localStorage.getItem('url_api')+path+'/'+props.fileData[i]} />
                </a>
            </div>
        );
        
        return content;

    }

    const ChoseeFile = (props) => {
        
        const content = Object.keys(props.linkPushImage).map((d, i) =>
            (props.linkPushImage[i] == null || props.linkPushImage[i] == '') ?
            (<div className="chosee-image-item" key={i}>
                <img src='https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg' />
            </div>)
            : 
            (<div className="chosee-image-item" key={i}>
                <img src={localStorage.getItem('url_api')+path+'/'+props.linkPushImage[i]} />
            </div>)
        );
        
        return content;

    }

    useEffect(() => {
        getData(page);
    }, []);

    return (
        <>
            <div className={linkPushImage.length > 1 ? 'chosee-image-list' : 'chosee-image'}>
                <ChoseeFile linkPushImage={linkPushImage} />
            </div>
            <div className="btn-group my-1">
                <button type="button" className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target={'#myModalUploadButton'+idName}>
                    <span className="icon text-white-50">
                        <i className="bx bx-upload"></i> 
                    </span>
                    <span className="text">{params.title ? params.title : 'Tải lên'}</span>   
                </button>
                <button type="button" className="btn btn-sm btn-danger" onClick={() => {params.value([]); setLinkPushImage([])}} >
                    <i className='bx bx-trash'></i>
                </button>
            </div>
            <div className="modal fade" id={'myModalUploadButton'+idName}  data-bs-backdrop="static">
                <div className="modal-dialog modal-xl  modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h6 className="modal-title">Thêm Tệp Tin</h6>
                            <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                        </div>
                        

                        <div className="modal-body modal-body-bg-eee" style={{ height: '70vh'}}>
                            <div className="upload-head">
                                <div className="form-group input-group-sm row d-none">
                                    <input multiple={multiple} name="file" id="file" type="file" onChange={handleChange} />
                                </div>
                                <div className="imageuploadify well">
                                    <div className="imageuploadify-images-list text-center">
                                        <label htmlFor="file">
                                            <i className="bx bxs-cloud-upload"></i>
                                            <span className="imageuploadify-message">Nhấn vào đây</span>
                                        </label>                   
                                    </div>
                                </div>
                            </div>

                            <div className='upload-body py-2'>
                                <div className="row upload-body-detail">

                                    <AllFileData fileData={fileData}/>

                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button onClick={() => { setPage(1); getData(1) }} type="button" className="btn btn-sm btn-info text-white">Tải lại</button>                          
                        {fileData.length > 59 && <button onClick={() => { setPage(page + 1); getData(page + 1) }} type="button" className="btn btn-sm btn-primary">Tải thêm 60 hình</button>} 
                            <button data-dismiss="modal" onClick={() => {multiple ? params.value(linkPushImage) : params.value(linkPushImage.toString()) }} type="button" data-bs-dismiss="modal"  className="btn btn-sm btn-success">Cập Nhật</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadButton;
import React , { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, Outle } from "react-router-dom";
import axios from 'axios';
import UploadButton from '../media/upload/UploadButton';
import Cookies from 'js-cookie';
import validator from 'validator';
import ReactQuill from 'react-quill';
import { useAlert } from 'react-alert';
import 'react-quill/dist/quill.snow.css';

// import image
import image1 from '../../../assets/images/home/1.png';
import image2 from '../../../assets/images/home/2.png';
import image3 from '../../../assets/images/home/3.png';
import image4 from '../../../assets/images/home/4.png';
import image5 from '../../../assets/images/home/5.png';
import image6 from '../../../assets/images/home/6.png';
import image7 from '../../../assets/images/home/7.png';
import image8 from '../../../assets/images/home/8.png';
import image9 from '../../../assets/images/home/9.png';

// import home
import Home1 from  './Home/Home1';
import Home2 from  './Home/Home2';
import Home3 from  './Home/Home3';
import Home4 from  './Home/Home4';
import Home5 from  './Home/Home5';
import Home6 from  './Home/Home6';
import Home7 from  './Home/Home7';
import Home8 from  './Home/Home8';
import Home9 from  './Home/Home9';


const View = () => {
    const [home, setHome] = useState(1);


    return (
        <div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Giao diện trang chủ</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Giao diện trang chủ</h6>
                        </div>
                        <div className='col-6 text-end'>

                        </div>
                    </div>
                    <hr/>
                    <form>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className="card">
                                    <div className="card-body">
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(1)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image1}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(2)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image2}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(3)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image3}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(4)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image4}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(5)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image5}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(6)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image6}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(7)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image7}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(8)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image8}/>
                                        </button>
                                        <button type={'button'} className='btn btn-link' onClick={() => {setHome(9)}}>
                                            <img style={{width: '100%', border: '4px solid #101010', display: 'block'}} src={image9}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className="card">
                                {/* sticky-top-cw */}
                                    <div className=''>
                                        <div className="card-body">
                                            { home === 1 && <Home1/> }
                                            { home === 2 && <Home2/> }
                                            { home === 3 && <Home3/> }
                                            { home === 4 && <Home4/> }
                                            { home === 5 && <Home5/> }
                                            { home === 6 && <Home6/> }
                                            { home === 7 && <Home7/> }
                                            { home === 8 && <Home8/> }
                                            { home === 9 && <Home9/> }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}

export default View;
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.svg';
import React , { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import axios from 'axios';
import Cookies from 'js-cookie';

function Sidebar() {
    const alert = useAlert();
    const [roles, setRoles] = useState([]);

    const getData = async () => {
        if(localStorage.getItem('menu_current')) {
            setRoles(JSON.parse(localStorage.getItem('menu_current')));
        } else {
            axios.get(localStorage.getItem('url_api')+'api/auth/menu/view/0',
            {
                headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
                }
            })
            .then(function (response) {
                if (response.constructor === String) {
                    response = JSON.parse(response);
                } 

                setRoles(response.data.menu);
                localStorage.setItem('menu_current', JSON.stringify(response.data.menu));
                return window.location.pathname = '.'; 
            })
            .catch(function (error) {
                return alert.info(error.message);
            });
        }
    }

    useEffect(() => {
        getData();
    }, []);
    
    const LoadingRoleItemMenu = (props) => {
        const content = Object.keys(props.items).map((item, index) =>
            <li key={index} > 
                <Link to={props.slug+'/'+props.items[item]['slug']}>
                    <i className="bx bx-right-arrow-alt"></i>{props.items[item]['name_vn']}
                </Link>
            </li>
        );
        
        return (
            <ul>
                {content}
            </ul>
        );
    }

    const LoadingRoleMenu = (props) => {
            const content = Object.keys(props.roles).map((role, index) =>
                <li key={index}>
                    {
                        props.roles[role]['submenu'][1] ? (
                            <>
                                <a className="has-arrow" href={this}>
                                    <div className="parent-icon">
                                        <i className={props.roles[role]['icon']+' fa-fw'}></i>
                                    </div>
                                    <div className="menu-title">{props.roles[role]['name_vn']} {}</div>
                                </a>
                                <LoadingRoleItemMenu slug={props.roles[role]['slug']} items={props.roles[role]['submenu']}/>
                            </>
                        ) : (
                            <Link to={props.roles[role]['slug']+'/'+props.roles[role]['submenu'][0]['slug']}>
                                <div className="parent-icon">
                                    <i className={props.roles[role]['icon']+' fa-fw'}></i>
                                </div>
                                <div className="menu-title">{props.roles[role]['name_vn']}</div>
                            </Link>
                        )
                    }
                </li>

            );

        return (content);
    }

    return (
        <div className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
            <div>
                <Link to="/">
                    <img src={Logo} className="logo-icon" alt="LOGO"/>
                </Link>
            </div>
            <div className="toggle-icon ms-auto"><i className='bx bx-menu-alt-left'></i>
            </div>
        </div>
        <ul className="metismenu" id="menu">
            <li className="menu-label">Mở rộng</li>
            <LoadingRoleMenu roles={roles}/>

            {/* <li className="menu-label">Khác</li> */}
            {/* <li>
                <a href={this}>
                    <div className="parent-icon"><i className="bx bx-folder"></i>
                    </div>
                    <div className="menu-title">Tài liệu</div>
                </a>
            </li>
            <li>
                <a href={this}>
                    <div className="parent-icon"><i className="bx bx-support"></i>
                    </div>
                    <div className="menu-title">Hỗ trợ</div>
                </a>
            </li> */}
        </ul>
    </div>
    );
}

export default Sidebar;
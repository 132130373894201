import React, { useState } from 'react';
import validator from 'validator';
import { useAlert } from 'react-alert';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

function ForgotPassword() {
    const [email, setEmail ] =  useState('');
    const [userId, setUserId ] =  useState('');
    const [key, setKey ] =  useState('');
    const [newPassword, setNewPassword ] =  useState('');
    const [confirmPassword, setConfirmPassword ] =  useState('');
    const alert = useAlert();

    const ResetForm = () => { 
        setEmail('');
        setKey('');
        setConfirmPassword('');
        setNewPassword('');
    }

    const HandleForgotPasswordSendMail = () => {
        $('#forgotPassword').text('Đang gửi ...');

        
        if(validator.isEmpty(email)) {
            $('#forgotPassword').text('Gửi xác nhận');
            return alert.info('Cần nhập vào email');
        }
        if(!validator.isEmail(email)) {
            $('#forgotPassword').text('Gửi xác nhận');
            return alert.info('Địa chỉ Email không chính xác');
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/forgot-password', 
            {
                email : email
            }
        )
        .then((response) => {
            console.log();
            $('#forgotPassword').text('Gửi xác nhận');
            alert.success(response.data.message);
            setUserId(response.data.id);
        })
        .catch((error) => {
            $('#forgotPassword').text('Gửi xác nhận');
            alert.error(error.data.message);
        });
    }

    const HandleResetPassword = () => {
        $('#forgotPassword').text('Đang gửi ...');
        
        if(validator.isEmpty(key)) {
            $('#forgotPassword').text('Đổi mật khẩu');
            return alert.info('Mã xác nhận không được để trống.');
        }

        if(validator.isEmpty(newPassword)) {
            $('#forgotPassword').text('Đổi mật khẩu');
            return alert.info('Cần nhập vào mật khẩu mới.');
        }

        if(validator.isEmpty(confirmPassword)) {
            $('#forgotPassword').text('Đổi mật khẩu');
            return alert.info('Nhập mật khẩu xác nhận.');
        }

        if(confirmPassword !== newPassword) {
            $('#forgotPassword').text('Đổi mật khẩu.');
            return alert.info('Mật khẩu không khớp');
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/change-password', 
            {
                'key' : key,
                'users_id' : userId,
                'new_password' : newPassword,
                'confirm_password' : confirmPassword,
            }
        )
        .then((response) => {
            $('#forgotPassword').text('Đổi mật khẩu');
            ResetForm();
            return alert.success(response.data.message);
        })
        .catch((error) => {
            $('#forgotPassword').text('Đổi mật khẩu');
            return alert.error(error.data.message);
        });
    }

    const showPassword = (event) => {
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") == "text") {
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass("bx-hide");
            $('#show_hide_password i').removeClass("bx-show");
        } else if ($('#show_hide_password input').attr("type") == "password") {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass("bx-hide");
            $('#show_hide_password i').addClass("bx-show");
        }
    }

    return userId ? (
            <div className="card forgot-box">
                <div className="card-body">
                    <div className="p-4 rounded  border">
                        <div className="text-center">
                            <h3 className="">Thay đổi mật khẩu?</h3>
                            <p>Nhập vào mật khẩu bạn cần đặt lại</p>
                        </div>
                        <div className="form-body">
                            <form className="row g-3">
                                <div className="col-12">
                                    <label htmlFor="key" className="form-label">Mã xác nhận</label>
                                    <div className="input-group">
                                        <input type="text" onChange={(e) => setKey(e.target.value)} defaultValue={key} id="key" className="form-control border-end-0"  placeholder="Nhập xác nhận"/> 
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="newPassword" className="form-label">Mật khẩu</label>
                                    <div className="input-group" id="show_hide_password">
                                        <input type="password" onChange={(e) => setNewPassword(e.target.value)} defaultValue={newPassword} className="form-control border-end-0" id="newPassword" placeholder="Nhập mật khẩu"/> 
                                        <button onClick={showPassword} event="true" type="button" className="input-group-text bg-transparent">
                                            <i className='bx bx-hide'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="confirmPassword" className="form-label">Mật khẩu xác nhận</label>
                                    <div className="input-group" id="show_hide_password">
                                        <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} defaultValue={confirmPassword}  className="form-control border-end-0" id="confirmPassword" placeholder="Nhập mật khẩu xác nhận"/> 
                                        <button onClick={showPassword} event="true" type="button" className="input-group-text bg-transparent">
                                            <i className='bx bx-hide'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-grid">
                                        <button type="button" id="forgotPassword" onClick={HandleResetPassword} className="btn btn-primary">Đổi mật khẩu</button> 
                                        <Link to="/login" className="btn btn-light mt-1"><i className='bx bx-arrow-back me-1'></i>Đăng nhập</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        ):(
            <div className="card forgot-box">
                <div className="card-body">
                    <div className="p-4 rounded  border">
                        <div className="text-center">
                            <h3 className="">Quên mật khẩu?</h3>
                            <p>Nhập vào địa chỉ email của bạn để xác nhận tài khoản</p>
                        </div>
                        <div className="form-body">
                            <form className="row g-3" onSubmit={HandleForgotPasswordSendMail}>
                                <div className="col-12">
                                    <label htmlFor="inputEmailAddress" className="form-label">Email</label>
                                    <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} defaultValue={email} id="inputEmailAddress" placeholder="Nhập vào email"/>
                                </div>
                                <div className="col-12">
                                    <div className="d-grid">
                                        <button type="button" onClick={HandleForgotPasswordSendMail} className="btn btn-primary" id="forgotPassword">Gửi xác nhận</button> 
                                        <Link to="/login" className="btn btn-light mt-1"><i className='bx bx-arrow-back me-1'></i>Đăng nhập</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
}
export default ForgotPassword;
import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import validator from 'validator';
import { useAlert } from 'react-alert';
import axios from 'axios';
import Cookies from 'js-cookie';

function Set() {
    const alert = useAlert();
    const [roles, setRoles] = useState([]);
    const [name, setName] = useState([]);
    let { key } = useParams();

    const getData = async () => {
        axios.get(localStorage.getItem('url_api')+'api/auth/role/set/'+key,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setRoles(response.data.data);
            setName(response.data.name);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const onClickOption = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const menuid = target.getAttribute('menuid');

        if(!value){
            alert.info('Cần chọn phân quyền');
            return 0;
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/role/setting', {
            role_key : key,
            access : value,
            menu_id : menuid,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            return alert.success(response.data);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const LoadingOptionItem = (props) => {
        const content = props.options.map((option, index) =>
            <option value={option.value} key={index}>{option.label}</option>
        );
        return content;
    }

    const LoadingRoleItem = (props) => {
        const content = Object.keys(props.items).map((item, index) =>
            <li  key={index} className="mb-1"> 
                <form method="POST">
                    <div className="row">
                        <div className="col">
                            <label>{props.items[item]['name_vn']}</label>
                        </div>
                        <div className="col-5">
                            <select onChange={onClickOption} defaultValue={props.items[item]['access']} className="form-select form-select-sm w-100" menuid={props.items[item]['id']}> 
                                <option value=''>Chọn quyền</option>
                                <LoadingOptionItem options={props.items[item]['role']} />
                            </select>
                        </div>
                    </div>
                </form>
            </li>
        );
        
        return (
            <ul className="list-unstyled">
                {content}
            </ul>
        );
    }

    const LoadingRole = (props) => {
        const content = Object.keys(props.roles).map((role, index) =>

            <div key={index} className="col-md-4">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">
                            <i className={props.roles[role]['icon']}></i> &nbsp;
                            {props.roles[role]['name_vn']}
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="chart-pie p-1">
                            <LoadingRoleItem items={props.roles[role]['submenu']}/>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="row">
                {content}
            </div>
        );
    }

    return roles ? <div  className="page-content">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-1 text-gray-800">Phân quyền</h1>
            <Link to="/role/view" type="button" className="d-none d-sm-inline-block btn btn-sm btn-secondary btn-icon-split" >
                <span className="icon text-white-50">
                    <i className="bx bx-arrow-back"></i>
                </span>
                <span className="text">Trở lại</span>   
            </Link>
        </div>
        <div className="row">

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header py-3">
                        <h4>{name}</h4>
                    </div>
                    
                    <div className="card-body card-body-custom">
                            
                    <LoadingRole roles={roles}/>
                            
                    </div>
                </div>
            </div>
        </div>
    </div>:<></>
}
export default Set;
import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import $ from 'jquery';
import Cookies from 'js-cookie';
import validator from 'validator';

function View() {
    const alert = useAlert();
    const [name, setName] = useState('');
    const [key, setKey] = useState('user_create');
    const [required, setRequired] = useState('0');
    const [type, setType] = useState('string');
    const [id, setId] = useState('');
    const [setting, setSetting] = useState([]);

    const onClickClose = () => {
        setKey('');
        setName('');
        setRequired('');
        setType('');
        setId('');
    }

    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/user_create_view', {
            key: key,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setSetting(response.data.data);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const handleDelete = (event) => {
        axios.get(localStorage.getItem('url_api')+'api/auth/setting/user_create_remove/'+id,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            onClickClose();
            getData();
            return alert.success(response.data.message);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const Hanld = () => {
        if(validator.isEmpty(key)){
            return alert.info('Cần nhập vào mã phân quyền');
        }
        
        if(validator.isEmpty(name)){
            return alert.info('Cần nhập vào tên phân quyền');
        }
        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/user_create_add', {
            name : name, 
            key: key,
            type: type,
            required: required,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.constructor === String) {
                res = JSON.parse(res);
            }

            if(res.data.success == true){
                getData();
                return alert.success(res.data.message);
            }else{
                return alert.error(res.data.message);
            }
        })
        .catch(result => {
            return alert.error('Lỗi truy cập trang');
        });
    }

    const handleConformDelete = (event) => {
        setId(event.id);
    }

    const Loading = (props) => {
        const content = props.settings.map((setting, index) =>
            <tr key={index}>
                <td className="text-center">{index+1}</td>
                <td>{setting.key}</td>
                <td>{setting.name}</td>
                <td className="text-center">{setting.created_at}</td>
                <td className="text-center">{setting.created_user}</td>
                <td  className="text-center">
                    <button type="button" onClick={() => handleConformDelete(setting)} className="btn btn-primary ml-1 btn-sm " title="Xóa phân quyền" data-toggle="modal" data-target="#myModalRemove"><i className="fas fa-trash"></i></button>
                </td>
            </tr>
        );
        return (
            <tbody>
                {content}
            </tbody>
        );
    }

    return <div className="page-content">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-1 text-gray-800">Cài đặt thêm dữ liệu người dùng</h1>
            <button type="button" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm btn-icon-split"  data-toggle="modal" data-target="#myModalRole">
                <span className="icon text-white-50">
                    <i className="fas fa-plus fa-sm text-white-50"></i> 
                </span>
                <span className="text">Thêm</span>   
            </button>
        </div>
        <div className="row">

            <div className="col-lg-12">
                <div className="card">
                    {/* <div className="card-header py-3">
                        <form>
                            <div className="row">
                                <div className="col-md-3">
                                    <input type="text" className="form-control" placeholder="Tìm" name="search"/>
                                </div>
                                <div className="col-md-2">
                                    <button type="button" className="btn btn-success btn-icon-split">
                                        <span className="icon text-white-50">
                                            <i className="fas fa-search"></i>
                                        </span>
                                        <span className="text">Tìm</span>    
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div> */}
                    
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Tiêu đề</th>
                                        <th className="text-center">Mã</th>
                                        <th className="text-center">Ngày tạo</th>
                                        <th className="text-center">Người tạo</th>
                                        <th className="text-center">Cập nhật</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Tiêu đề</th>
                                        <th className="text-center">Mã</th>
                                        <th className="text-center">Ngày tạo</th>
                                        <th className="text-center">Người tạo</th>
                                        <th className="text-center">Cập nhật</th>
                                    </tr>
                                </tfoot>
                                
                                <Loading settings={setting}/>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal" id="myModalRole">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Thêm vài trò</h4>
                        <button type="button" onClick={() => onClickClose()} className="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div className="modal-body">
                        <form method='POST'>
                            <div className="form-group input-group-sm row">
                                <div className="col-12">
                                    <label>Tiêu đề: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setKey(e.target.value)} defaultValue={key} type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="form-group input-group-sm row">
                                <div className="col-12">
                                    <label>Mã: <b className="text-danger">(*)</b></label>
                                    <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" className="form-control"/>
                                </div>
                            </div>
                            <div className="form-group input-group-sm row">
                                <div className="col-12">
                                    <label>Kiểu dữ liệu: </label>
                                    <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                                        <option value={'text'}>String</option>
                                        <option value={'number'}>Int</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group input-group-sm row">
                                <div className="col-12">
                                    <label>Bắt buộc: </label>
                                    <select required className="form-control" value={required} onChange={(e) => setRequired(e.target.value)}>
                                        <option value={'0'}>Không yêu cầu</option>
                                        <option value={'1'}>Yêu cầu</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="text-center">
                                <button onClick={() => Hanld()} data-dismiss="modal" type="button" className="btn btn-primary">Cập Nhật</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
        <div className="modal" id="myModalRemove">
            <div className="modal-dialog modal-md">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Xóa</h4>
                    <button type="button" onClick={() => onClickClose()} className="close" data-dismiss="modal">&times;</button>
                </div>

                <div className="modal-body">
                    <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> nội dung này không ?</p>
                </div>

                <div className="modal-footer">
                    <button type="button" onClick={() => onClickClose()} className="btn btn-secondary" data-dismiss="modal">Hủy</button>
                    <button type="button" onClick={() => handleDelete()} className="btn btn-primary" data-dismiss="modal">Xóa</button>
                </div>

                </div>
            </div>
        </div>
    </div>
}
export default View;
import React , { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import axios from 'axios';

const View = () => {
    const alert = useAlert();
    const [title1, setTitle1] = useState('');
    const [title2, setTitle2] = useState('');
    const [title3, setTitle3] = useState('');
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [content3, setContent3] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    // logo
    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'ykien_home'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var value = JSON.parse(response.data.value);
            setTitle1(value[0]['title']);
            setTitle2(value[1]['title']);
            setTitle3(value[2]['title']);
            
            setContent1(value[0]['content']);
            setContent2(value[1]['content']);
            setContent3(value[2]['content']);
            setLoading(true);

        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return loading && <div id='slider-cw'>
        <div className="col-5 p-2 b-1 border border-danger rounded bg-light"> 
            <label htmlFor="inputAddress" className="form-label">Nhập vào tiêu đề 1</label>
            <input type='text' className="form-control" id="inputAddress"  defaultValue={title1} onChange={(e) => setTitle1(e.target.value)} placeholder=""/>
            <input type='text' className="form-control mt-1" id="inputAddress"  defaultValue={content1} onChange={(e) => setContent1(e.target.value)} placeholder=""/>
        </div>
        <div className="col-5 p-2 mt-1 b-1 border border-danger rounded bg-light">
            <label htmlFor="inputAddress" className="form-label">Nhập vào tiêu đề 2</label>
            <input type='text' className="form-control" id="inputAddress"  defaultValue={title2} onChange={(e) => setTitle2(e.target.value)} placeholder=""/>
            <input type='text' className="form-control mt-1" id="inputAddress"  defaultValue={content2} onChange={(e) => setContent2(e.target.value)} placeholder=""/>
        </div>
        <div className="col-5 p-2 mt-1 b-1 border border-danger rounded bg-light">
            <label htmlFor="inputAddress" className="form-label">Nhập vào tiêu đề 1</label>
            <input type='text' className="form-control" id="inputAddress"  defaultValue={title3} onChange={(e) => setTitle3(e.target.value)} placeholder=""/>
            <input type='text' className="form-control mt-1" id="inputAddress"  defaultValue={content3} onChange={(e) => setContent3(e.target.value)} placeholder=""/>
        </div>
        <button type='button' className='mt-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'ykien_home', value : [
            {'title' : title1, 'content' : content1},
            {'title' : title2, 'content' : content2},
            {'title' : title3, 'content' : content3}
        ]})}>Lưu</button>
                               
    </div>
}
export default View;
import React , { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import validator from 'validator';
import axios from 'axios';
import $ from 'jquery';
import Cookies from 'js-cookie';
import Breadcrumb from '../Breadcrumb';

function View() {
    const alert = useAlert();
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const [roles, setRoles] = useState([]);
    const [id, setId] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    let [page, setPage] = useState(searchParams.get("page")? searchParams.get("page"): 1);

    const onClickEdit = (event) => {
        setKey(event.key);
        setName(event.name);
        setId(event.id);
    }

    const onClickClose = () => {
        setKey('');
        setName('');
        setId('');
    }

    const handleConformDelete = (event) => {
        setId(event.id);
    }

    const handleDelete = (event) => {
        axios.get(localStorage.getItem('url_api')+'api/auth/role/remove/'+id,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            onClickClose();
            getData();
            return alert.success(response.data.message);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const LoadingRole = (props) => {
        const content = props.roles.map((role, index) =>
            <tr key={index}>
                <td className="text-center">{index+1}</td>
                <td>{role.key}</td>
                <td>{role.name}</td>
                <td className="text-center">{role.created_at}</td>
                <td className="text-center">{role.updated_at}</td>
                <td className="text-center">{role.created_user}</td>
                <td  className="text-center">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={'/role/set/'+role.key} className="btn btn-warning btn-sm" type="button">
                            <i className="bx bx-shield-quarter text-white"></i>
                        </Link> 
                        <button type="button" onClick={() => onClickEdit(role)} className="btn btn-success ml-1 btn-sm" title="Chỉnh phân quyền" data-bs-toggle="modal" data-bs-target="#myModalRoleUpdate"><i className="bx bx-edit"></i></button>
                        <button type="button" onClick={() => handleConformDelete(role)} className="btn btn-primary btn-sm ml-1" title="Xóa phân quyền" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );
        return (
            <tbody>
                {content}
            </tbody>
        );
    }

    const getData = async () => {
        axios.get(localStorage.getItem('url_api')+'api/auth/role/view?page='+page, 
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            setRoles(response.data);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const HanldRole = () => {
        if(validator.isEmpty(key)){
            return alert.info('Cần nhập vào mã phân quyền');
        }
        
        if(validator.isEmpty(name)){
            return alert.info('Cần nhập vào tên phân quyền');
        }
        
        axios.post(localStorage.getItem('url_api')+'api/auth/role/add', {
            name : name, 
            key: key,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.constructor === String) {
                res = JSON.parse(res);
            }

            if(res.data.success == true){
                getData();
                return alert.success(res.data.message);
            }else{
                return alert.error(res.data.message);
            }
        })
        .catch(result => {
            return alert.error('Lỗi truy cập trang');
        });
    }

    const hanldRoleUpdate = () => {
        if(validator.isEmpty(key)){
            return alert.info('Cần nhập vào mã phân quyền');
        }
        
        if(validator.isEmpty(name)){
            return alert.info('Cần nhập vào tên phân quyền');
        }
        
        axios.post(localStorage.getItem('url_api')+'api/auth/role/update', {
            name : name, 
            key: key,
            id: id,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.constructor === String) {
                res = JSON.parse(res);
            }

            if(res.data.success == true){
                getData();
                return alert.success(res.data.message);
            }else{
                return alert.error(res.data.message);
            }
        })
        .catch(result => {
            return alert.error('Lỗi truy cập trang');
        });
    }

    useEffect(() => {
        getData();
    }, []);

    return roles.length > 0 ? <div  className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
                <Breadcrumb />
            </div>
            <div className="ms-auto">
                {/* <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleFullScreenModal">
                <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button> */}
                <button type="button" className="mx-1 btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#myModalRole">
                <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</button>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-12 mx-auto">
                <h6 className="mb-0 text-uppercase">Phân quyền</h6>
                <hr/>
                <div className="card">
                    
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Mã vai trò</th>
                                        <th className="text-center">Tên vai trò</th>
                                        <th className="text-center">Ngày vai trò</th>
                                        <th className="text-center">Ngày cập nhật</th>
                                        <th className="text-center">Người tạo</th>
                                        <th className="text-center">Cập nhật</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Mã vai trò</th>
                                        <th className="text-center">Tên vai trò</th>
                                        <th className="text-center">Ngày vai trò</th>
                                        <th className="text-center">Ngày cập nhật</th>
                                        <th className="text-center">Người tạo</th>
                                        <th className="text-center">Cập nhật</th>
                                    </tr>
                                </tfoot>
                                <LoadingRole roles={roles}/>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="myModalRole" data-bs-backdrop="static">
            <div className="modal-dialog">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Thêm vài trò</h4>
                    <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                </div>

                <div className="modal-body">
                    <form method='POST'>
                        <div className="form-group input-group-sm">
                            <label htmlFor="usr">Mã phân quyền: <b className="text-danger">(*)</b></label>
                            <input onChange={(e) => setKey(e.target.value)} defaultValue={key} type="text" className="form-control"/>
                        </div>
                        <div className="form-group input-group-sm">
                            <label htmlFor="pwd">Tên phân quyền: <b className="text-danger">(*)</b></label>
                            <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" className="form-control"/>
                        </div>
                        
                        <div className="text-center my-2">
                            <button onClick={HanldRole} data-bs-dismiss="modal" type="button" className="btn btn-primary">Cập Nhật</button>
                        </div>
                    </form>
                </div>

                </div>
            </div>
        </div>

        <div className="modal fade" id="myModalRoleUpdate" data-bs-backdrop="static">
            <div className="modal-dialog">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Chỉnh vài trò</h4>
                    <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                </div>

                <div className="modal-body">
                    <form method='POST'>
                        <div className="form-group input-group-sm">
                            <label htmlFor="usr">Mã phân quyền: <b className="text-danger">(*)</b></label>
                            <input onChange={(e) => setKey(e.target.value)} defaultValue={key} type="text" className="form-control"/>
                        </div>
                        <div className="form-group input-group-sm">
                            <label htmlFor="pwd">Tên phân quyền: <b className="text-danger">(*)</b></label>
                            <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" className="form-control"/>
                        </div>
                        
                        <div className="text-center my-2">
                            <button onClick={() => hanldRoleUpdate()} data-bs-dismiss="modal" type="button" className="btn btn-primary">Cập Nhật</button>
                        </div>
                    </form>
                </div>

                </div>
            </div>
        </div>

        <div className="modal fade" id="myModalRemove" data-bs-backdrop="static">
            <div className="modal-dialog modal-md  modal-dialog-centered">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Xóa vài trò</h4>
                        <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                </div>

                <div className="modal-body">
                    <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> vài trò này không ?</p>
                </div>

                <div className="modal-footer">
                    <button type="button" onClick={() => onClickClose()} className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                    <button type="button" onClick={() => handleDelete()} className="btn btn-primary" data-bs-dismiss="modal">Xóa vai trò</button>
                </div>

                </div>
            </div>
        </div>

    </div> : <></>
}
export default View;
import React , { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const View = () => {
    const [tableData, setTableData] = useState([]);
    const [countData, setCountData] = useState(1);
    const [isSee, setIsSee] = useState('');
    const [contact, setContact] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(25);

    const getData = async (page) => {
        setTableData('');
        axios.post(localStorage.getItem('url_api')+'api/auth/contact/view', {
            page : page,
            is_see : isSee,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTableData(response.data.data);
            setCountData(response.data.count);
        });
    }

    const reSet = async () => {
        setIsSee('');
        document.getElementById("filter-form").reset();
    }

    const updateContact = (d) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/contact/is_see', {
            id : d.id,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
        });

        setContact(d);

        getData(page);

    }

    const TableDataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <tr key={i}>
                <td className="text-center align-middle">{i+1}</td>
                <td className='align-middle'>{d.full_name}</td>
                <td className='align-middle'><Link target={'_blank'} to={'/school/edit/'+d.school_id}>Xem thông tin trường</Link></td>
                <td className='align-middle'>{d.email}</td>
                <td className='align-middle'>{d.phone}</td>
                <td className='align-middle'>{d.date}</td>
                <td className='align-middle' style={{width: '5%'}} className="text-center">
                    <button type="button" onClick={() => updateContact(d)} className={d.is_see === 1 ? 'btn btn-success btn-sm ml-1' : 'btn btn-primary btn-sm ml-1'} title="Xem" data-bs-toggle="modal" data-bs-target="#btnContact">
                        {d.is_see === 1 ? <i className="bx bx-show d-content"></i> : <i className="bx bx-hide d-content"></i>}
                    </button>
                </td>
            </tr>
        );

        return content ? content : (
            <tr>
                <td className='text-center' colSpan={7}> 
                    <div className="spinner-border text-danger" role="status"> 
                        <span className="visually-hidden">Loading...</span>
					</div>
                </td>
            </tr>
        );
    }

    useEffect(() => {
        getData(page);
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Liên hệ</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#btnFilter">
                    <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        
                        <div className='row'>
                            <div className='col-6'>
                                <h6 className="mb-0 text-uppercase">Liên hệ</h6>
                            </div>
                            <div className='col-6 text-end'>
                                <span className="mb-0">Trang hiện thời là {page}/{Math.ceil(countData / limit)}. Có tổng {countData} liên hệ</span>
                            </div>
                        </div>
                        <hr/>

                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table id="example" className="table table-striped table-bordered" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th className="text-center align-middle" style={{width: '2%'}}>STT</th>
                                                <th className="text-center align-middle">Họ và tên</th>
                                                <th className="text-center align-middle">Chi tiết</th>
                                                <th className="text-center align-middle">Email</th>
                                                <th className="text-center align-middle">Điện thoại</th>
                                                <th className="text-center align-middle">Ngày</th>
                                                <th className="text-center align-middle">Cập nhật</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData ? <TableDataBody tableData={tableData}/> :  <tr>
                                                    <td className='text-center' colSpan={7}> 
                                                        <div className="spinner-border spinner-border-sm text-danger" role="status"> 
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="text-center align-middle">STT</th>
                                                <th className="text-center align-middle">Họ và tên</th>
                                                <th className="text-center align-middle">Chi tiết</th>
                                                <th className="text-center align-middle">Email</th>
                                                <th className="text-center align-middle">Điện thoại</th>
                                                <th className="text-center align-middle">Ngày</th>
                                                <th className="text-center align-middle">Cập nhật</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <PaginationControl
                                        page={page}
                                        between={4}
                                        total={countData}
                                        limit={limit}
                                        changePage={(page) => {
                                            setPage(page); 
                                            getData(page);
                                            window.scrollTo(0,0);
                                        }}
                                        ellipsis={1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* form tìm kiếm */}
            <div className="modal fade" id="btnFilter" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title text-sm">Lọc</h4>
                            <button type="button"  className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form method='POST' id="filter-form">
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Trạng thái: <b className="text-danger"></b></label>
                                <select className="form-control" value={isSee} onChange={(e) => setIsSee(e.target.value)}>
                                    <option value={''}>== Trạng thái ==</option>
                                    <option value={0}>Chưa xem</option>
                                    <option value={1}>Đã xem</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => reSet()} className="btn btn-primary btn-sm">Xóa</button>
                        <button type="button" onClick={() => { setPage(1); getData(1)}} className="btn btn-success btn-sm" data-bs-dismiss="modal">Lọc</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* Xem chi tiết trường */}
            <div className="modal fade" id="btnContact" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title text-sm">Xem chi tiết liên hệ</h4>
                            <button type="button"  className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <p>
                            <strong className='text-start'>Họ và tên: </strong>
                            <span  className='text-end'>{contact.full_name}</span>
                        </p>
                        <p>
                            <strong className='text-start'>Email: </strong>
                            <span  className='text-end'>{contact.email}</span>
                        </p>
                        <p>
                            <strong className='text-start'>Điện thoại: </strong>
                            <span  className='text-end'>{contact.phone}</span>
                        </p>
                        <p>
                            <strong className='text-start'>Nội dung: </strong>
                            <span  className='text-end'>{contact.content}</span>
                        </p>
                        <p>
                            <strong className='text-start'>Trạng thái: </strong>
                            <span  className='text-end'>Đã xem</span>
                        </p>
                    </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default View;
import React , { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Cookies from 'js-cookie';
import './assets/all.css';
import $ from 'jquery';
import packageJson from '../package.json';

import reportWebVitals from './reportWebVitals';

import Layout from "./App"; 
import Outside from "./Out"; 
import AlertTemplate from "./components/inc/AlertTemplate"; 

// =========================== chỉ dùng ở trang chưa đăng nhập
import Login from  './components/vn/user/Login';
import Signup from  './components/vn/user/Signup';
import ForgotPassword from  './components/vn/user/ForgotPassword';
import ConfirmUser from  './components/vn/user/ConfirmUser';


// =========================== chỉ dùng cho trang đã đăng nhập 
import Home from  './components/vn/dashboard/View';
import Dashboard from  './components/vn/dashboard/View';

// user
import UserView from  './components/vn/user/View';
import UserAdd from  './components/vn/user/View';
import UserProfile from  './components/vn/user/UserProfile';

// setting
import UserCreateView from  './components/vn/setting/userCreateView';
import MenuView from  './components/vn/setting/menuView';
import SettingExtension from  './components/vn/setting/extension';

// role
import RoteView from  './components/vn/role/view';
import RoteSet from  './components/vn/role/set';

// category
import CategoryView from  './components/vn/category/View';
import CategoryEdit from  './components/vn/category/Edit';

// School
import SchoolView from  './components/vn/school/View';
import SchoolAdd from  './components/vn/school/Add';
import SchoolEdit from  './components/vn/school/Edit';
import SchoolRating from  './components/vn/school/Rating';
import SchoolContent from  './components/vn/school/Content';

// post
import ContactView from  './components/vn/contact/View';

// post
import PostView from  './components/vn/post/View';
import PostAdd from  './components/vn/post/Add';
import PostEdit from  './components/vn/post/Edit';

// post
import HomeView from  './components/vn/home/View';

import NotFound from  './components/vn/404/View';


// =========================== dùng cho cả đã đăng nhập và chưa đăng nhập


export default function App() {
  const [authToken, setAuthToken] = useState(Cookies.get('auth_token_user')); 

  const eventLoading = async () => {

    if(!localStorage.getItem('url_api')||!localStorage.getItem('homepage')|| !authToken) {
      localStorage.setItem('url_api', packageJson.url_api);
      localStorage.setItem('homepage', packageJson.homepage);
    }

    $(document).ready(function() {
      $.getScript('/assets/scripts/all.min.js');
    });
    
  }

  useEffect(() => {
    eventLoading();
  }, []);

  const options = {
      // you can also just use 'bottom center'
      position: positions.BOTTOM_LEFT,
      timeout: 6000,
      offset: '5px',
      // you can also just use 'scale'
      transition: transitions.FADE
  }
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      {
        authToken ? (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout setAuthToken={setAuthToken}/>}>
                <Route index element={<Home />} />
                <Route path="dashboard" element={<Dashboard />} />
                {/* user */}
                <Route path="user/view" element={<UserView />} />
                <Route path="user/add" element={<UserAdd />} />
                <Route path="user/user-profile/:id" element={<UserProfile />} />
                {/* setting */}
                <Route path="setting/user-create-view" element={<UserCreateView />} />
                <Route path="setting/menu-view" element={<MenuView />} />
                <Route path="setting/extension" element={<SettingExtension />} />
                {/* category */}
                <Route path="category/view" element={<CategoryView />} />
                <Route path="category/edit/:id" element={<CategoryEdit />} />
                {/* school */}
                <Route path="school/view" element={<SchoolView />} />
                <Route path="school/add" element={<SchoolAdd />} />
                <Route path="school/edit/:id" element={<SchoolEdit />} />
                <Route path="school/rating/:id" element={<SchoolRating />} />
                <Route path="school/content/:id" element={<SchoolContent />} />
                {/* post */}
                <Route path="post/view" element={<PostView />} />
                <Route path="post/add" element={<PostAdd />} />
                <Route path="post/edit/:id" element={<PostEdit />} />
                {/* contact */}
                <Route path="contact/view" element={<ContactView />} />
                {/* home */}
                <Route path="home/view" element={<HomeView />} />
                {/* role */}
                <Route path="role/view" element={<RoteView />} />
                <Route path="role/set/:key" element={<RoteSet />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        ):(
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Outside />}>
                <Route index element={<Login setAuthToken={setAuthToken} />} />
                <Route path='*' element={<Login setAuthToken={setAuthToken} />} />
                <Route path='/login' element={<Login setAuthToken={setAuthToken} />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/confim-user/:key' element={<ConfirmUser />} />
              </Route>
            </Routes>
          </BrowserRouter>
        )
      }
    </AlertProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
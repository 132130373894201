import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import validator from 'validator';
import Select from "react-select";

function View() {
    const alert = useAlert();
    const [nameVN, setNameVN] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [icon, setIcon] = useState('');
    const [slug, setSlug] = useState('');
    const [parentId, setParentId] = useState(0);
    const [controller, setController] = useState('');
    const [object, setObject] = useState('');
    const [method, setMethod] = useState('');
    const [isActived, setIsActived] = useState(0);
    const [role , setRole] = useState([]);
    const [id, setId] = useState(0);
    const [roleList , setRoleList] = useState([]);
    const [menus, setMenus] = useState([]);

    const onClickClose = () => {
        setNameVN('');
        setNameEN('');
        setIcon('');
        setSlug('');
        setParentId(0);
        setController('');
        setObject('');
        setMethod('');
        setIsActived(0);
        setRole([]);
        setId(0);
    }

    const getData = async () => {
        axios.get(localStorage.getItem('url_api')+'api/auth/menu/view/1', 
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setMenus(response.data.menu);
            var roleListArr = [];
            Object.keys(response.data.role).map((key, index) =>
                roleListArr.push({ 
                    label: response.data.role[key], 
                    value: key
                })
            );
            setRoleList(roleListArr);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    const handleRemove = (event) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/menu/remove/',
        {
            id : id
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            onClickClose();
            getData();
            return alert.success(response.data);
        })
        .catch(function (error) {
            return alert.info(error.message);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const HanldAdd = () => {
        if(validator.isEmpty(nameVN)){
            return alert.info('Cần nhập tên tiếng việt');
        }
        
        if(validator.isEmpty(nameEN)){
            return alert.info('Cần nhập vào tiếng anh');
        }

        if(validator.isEmpty(slug)){
            return alert.info('Cần nhập vào đường dẫn');
        }
        
        if(validator.isEmpty(controller)){
            return alert.info('Cần nhập vào controller');
        }

        if(role.length === 0){
            return alert.info('Cần chọn phân quyền');
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/menu/add', {
            name_vn : nameVN, 
            name_en : nameEN, 
            parent_id : parentId, 
            controller : controller, 
            object : object, 
            method : method, 
            icon : icon, 
            slug : slug, 
            is_actived : isActived, 
            id : id, 
            role : role, 
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        }).then(result => {
            if (result.constructor === String) {
                result = JSON.parse(result);
            }

            getData();
            return alert.success(result.data);
        })
        .catch(result => {
            return alert.error('Lỗi truy cập trang');
        });
    }

    const handleConformRemove = (event) => {
        setId(event);
    }

    const handleConformEdit = (event) => {
        setNameVN(event['name_vn']);
        setNameEN(event['name_en']);
        setIcon(event['icon']);
        setSlug(event['slug']);
        setParentId(event['parent_id']);
        setController(event['controller']);
        setObject(event['object']);
        setMethod(event['method']);
        setIsActived(event['is_actived']);
        setRole(event['role']);
        setId(event['id']);
    }

    const LoadingItem = (props) => {
        const content = Object.keys(props.submenu).map((sub, index) =>
            <tr key={index}>
                <td className="text-left align-middle">
                    <i className="bx bx-right-arrow-alt text-warning"></i>&nbsp;
                    <b>{index+1}</b>.&nbsp;  
                    {props.submenu[sub]['name_vn']}
                </td>
                <td className="text-left align-middle">
                    <i className="bx bx-right-arrow-alt text-warning"></i>&nbsp;
                    {props.submenu[sub]['controller']}
                </td>
                <td className="text-center align-middle">{props.submenu[sub]['object']}</td>
                <td className="text-center align-middle">{props.submenu[sub]['method']}</td>
                <td className="text-center align-middle">{props.submenu[sub]['slug']}</td>
                <td className="text-center align-middle font-22"><i className={props.submenu[sub]['is_actived']== 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                <td className="text-center align-middle">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button type="button" onClick={() => handleConformEdit(props.submenu[sub])} className="btn btn-success ml-1 btn-sm" data-bs-toggle="modal" data-bs-target="#myModal"><i className="bx bx-edit text-white"></i></button>
                        <button type="button" onClick={() => handleConformRemove(props.submenu[sub]['id'])} className="btn btn-primary ml-1 btn-sm"  data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );
        return content;
    }

    const Loading = (props) => {
        const content = Object.keys(props.menus).map((menu, index) => 
            <tbody key={index}>
                <tr>
                    <td rowSpan={props.menus[menu]['submenu'].length + 1} className="text-center align-middle justify-content-center">{index+1}</td>
                    <td className="text-left align-middle">
                        <i className={props.menus[menu]['icon']+' text-primary'}></i> &nbsp;
                        {props.menus[menu]['name_vn']}
                    </td>
                    <td className="text-left align-middle">
                        <i className={props.menus[menu]['parent_id'] > 0 ? 'bx bx-right-arrow-alt text-warning':''}></i>&nbsp;
                        {props.menus[menu]['controller']}
                    </td>
                    <td className="text-center align-middle">{props.menus[menu]['object']}</td>
                    <td className="text-center align-middle">{props.menus[menu]['method']}</td>
                    <td className="text-center align-middle">{props.menus[menu]['slug']}</td>
                    <td className="text-center align-middle font-22"><i className={props.menus[menu]['is_actived']== 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                    <td className="text-center align-middle">
                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button type="button" onClick={() => handleConformEdit(props.menus[menu])} className="btn btn-success ml-1 btn-sm" data-bs-toggle="modal" data-bs-target="#myModal"><i className="bx bx-edit text-white"></i></button>
                            <button type="button" onClick={() => handleConformRemove(props.menus[menu]['id'])} className="btn btn-primary ml-1 btn-sm" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                        </div>
                    </td>
                </tr>
                
                <LoadingItem submenu={props.menus[menu]['submenu']}/> 
            </tbody>
            );
        return content;
    }

    const LodingParent = (props) => {
        const content =  Object.keys(props.menus).map((menu, index) =>
            props.menus[menu]['parent_id'] == 0 ?<option key={index} value={props.menus[menu]['id']}> {props.menus[menu]['name_vn']+' - '+props.menus[menu]['controller']} </option>:null
        );

        return content;
    }

    const handleOnChangeIsActived = () => {
        setIsActived(!isActived);
    };

    return menus ? (<div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Cài đặt danh mục</li>
                    </ol>
                </nav>
            </div>
            <div className="ms-auto">
                <button type="button" className="mx-1 btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#myModal">
                <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</button>
            </div>
        </div>
        <div className="row">
                <div className="col-xl-12 mx-auto">
                    <h6 className="mb-0 text-uppercase">Cài đặt danh mục</h6>
                    <hr/>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle">STT</th>
                                        <th className="text-center align-middle">Tên</th>
                                        <th className="text-center align-middle">Controller</th>
                                        <th className="text-center align-middle">Object</th>
                                        <th className="text-center align-middle">Kiểu</th>
                                        <th className="text-center align-middle">Đường dẫn</th>
                                        <th className="text-center align-middle font-22">
                                            <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                            <i className='text-warning bx bx-checkbox'></i>
                                        </th>
                                        <th className="text-center align-middle">Cập nhật</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th className="text-center align-middle">STT</th>
                                        <th className="text-center align-middle">Tên</th>
                                        <th className="text-center align-middle">Controller</th>
                                        <th className="text-center align-middle">Object</th>
                                        <th className="text-center align-middle">Kiểu</th>
                                        <th className="text-center align-middle">Đường dẫn</th>
                                        <th className="text-center align-middle font-22">
                                            <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                            <i className='text-warning bx bx-checkbox'></i>
                                        </th>
                                        <th className="text-center align-middle">Cập nhật</th>
                                    </tr>
                                </tfoot>
                                
                                <Loading menus={menus}/>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="myModal"  data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <form method='POST'>
                        <div className="modal-header">
                            <h4 className="modal-title">Thêm mới</h4>
                            <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                        </div>

                        <div className="modal-body">
                                <div className="form-group input-group-sm">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label htmlFor="pwd">Tên tiếng việt: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setNameVN(e.target.value)} defaultValue={nameVN} type="text" className="form-control"/>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="usr">Tên tiếng anh: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setNameEN(e.target.value)} defaultValue={nameEN} type="text" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group input-group-sm">
                                    <div className='row'>
                                        <div className='col-4'>
                                            <label htmlFor="pwd">Icon: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setIcon(e.target.value)} defaultValue={icon} type="text" className="form-control"/>
                                        </div>
                                        <div className="col-8">
                                            <label htmlFor="usr">Đường dẫn: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setSlug(e.target.value)} defaultValue={slug} type="text" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group input-group-sm">
                                    <div className='row'>
                                        <div className='col-7'>
                                            <label htmlFor="pwd">Controller: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setController(e.target.value)} defaultValue={controller} type="text" className="form-control"/>
                                        </div>
                                        <div className="col-5">
                                            <label htmlFor="usr">Object: <b className="text-danger">(*)</b></label>
                                            <input onChange={(e) => setObject(e.target.value)} defaultValue={object} type="text" className="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group input-group-sm">
                                    <div className='row'>
                                        <div className='col-8'>
                                            <label htmlFor="pwd">Danh mục cha: </label>
                                            <select className="form-control" value={parentId} onChange={(e) => setParentId(e.target.value)}>
                                                <option value="0">== Chọn danh mục cha ==</option>
                                                <LodingParent menus={menus} />
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="pwd">Phương thức: </label>
                                            <select className="form-control" value={method} onChange={(e) => setMethod(e.target.value)}>
                                                <option value={''}>== Chọn phương thức ==</option>
                                                <option value={'get'}>GET</option>
                                                <option value={'post'}>POST</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label htmlFor="pwd">Chọn quyền: <b className="text-danger">(*)</b></label>
                                            <Select
                                                defaultValue={role}
                                                isMulti
                                                placeholder={'Chọn quyền'}
                                                onChange={setRole}
                                                options={roleList}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <input type="checkbox" id="isActived" checked={isActived} onChange={handleOnChangeIsActived}  className="form-check-input"/> 
                                            <label htmlFor="isActived" className="form-check-label">
                                                Hiển thị ở danh mục này trên menu admin
                                            </label>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center">
                                <button onClick={() => HanldAdd()} data-bs-dismiss="modal" type="button" className="btn btn-primary">Cập Nhật</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div className="modal fade" id="myModalRemove"  data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">Xóa</h4>
                    <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                </div>

                <div className="modal-body">
                    <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> nội dung này không ?</p>
                </div>

                <div className="modal-footer">
                    <button type="button" onClick={() => onClickClose()} className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                    <button type="button" onClick={() => handleRemove()} className="btn btn-primary" data-bs-dismiss="modal">Xóa</button>
                </div>

                </div>
            </div>
        </div>
    </div>) : (<></>)
}
export default View;
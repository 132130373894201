import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import UploadButton from '../media/upload/UploadButton';
import Cookies from 'js-cookie';
import validator from 'validator';
import ReactQuill from 'react-quill';
import { useAlert } from 'react-alert';
import 'react-quill/dist/quill.snow.css';

const View = () => {
    const alert = useAlert();
    const { id } = useParams();
    const [category, setCategory] = useState([]);
    const [post, setPost] = useState([]);
    const [thumbnails, setThumbnails] = useState();
    const [categoryId, setCategoryId] = useState();
    const [title, setTitle] = useState();
    const [titleSeo, setTitleSeo] = useState();
    const [slug, setSlug] = useState();
    const [content, setContent] = useState();
    const [descriptionSeo, setDescriptionSeo] = useState();
    const [tags, setTags] = useState();
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(true);
    
    const getData = async () => {
        // Chi tiết bài viết
        axios.post(localStorage.getItem('url_api')+'api/auth/post/detail', {
            id: id
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setPost(response.data.data);
            setTitle(response.data.data.title);
            setContent(response.data.data.content);
            setSlug(response.data.data.slug);
            setThumbnails(response.data.data.thumbnails);
            setTags(response.data.data.tags);
            setCategoryId(response.data.data.category_id);
            setOrder(response.data.data.order);
            setLoading(false);

            setTitleSeo(response.data.seo.title);
            setDescriptionSeo(response.data.seo.description);
        });

        // danh mục
        axios.post(localStorage.getItem('url_api')+'api/auth/category/view', {
            limmit: 80,
            type: 'post'
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setCategory(response.data.data);
        });
    }

    
    const updateData = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/post/edit', {
            'id' : id, 
            'title' : title, 
            'content' : content, 
            'slug' : slug, 
            'thumbnails' : thumbnails, 
            'order' : order, 
            'tags' : tags,
            'category_id' : categoryId, 
            'title_seo' : titleSeo, 
            'description_seo' : descriptionSeo, 
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
            return window.location.pathname = '/post/edit/'+response.data.data.id; 
        })
        .catch(function (error) {
           
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            var arrError = error.response.data;
            if (arrError.constructor === String) {
                arrError = JSON.parse(arrError);
            }

            for (const key in arrError) {
                if (Object.hasOwnProperty.call(arrError, key)) {
                    const element = arrError[key];
                    for (let index = 0; index < element.length; index++) {
                        const e = element[index];
                        alert.info(e);
                    }
                }
            }

        });
    }

    useEffect(() => {
        getData();
    }, []);


    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item"><Link to={'/post/view'}>Bài viết</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{post.title ? post.title : 'Chỉnh bài viết'}</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <Link to="/post/view" className="btn btn-warning btn-sm text-white">
                        <i className="bx bx-arrow-back text-white"></i> Trở lại
                    </Link>
                    <button type="button" className="mx-1 btn btn-primary btn-sm text-white" onClick={() => updateData()}>
                        <i className="bx bx-save text-white"></i>Chỉnh bài viết
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Chỉnh bài viết</h6>
                        </div>
                        <div className='col-6 text-end'>

                        </div>
                    </div>
                    <hr/>

                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        loading === false && <fieldset>
                                            <legend className="title-legend">THÔNG TIN TRƯỜNG</legend>
                                            <hr/>

                                            <div className="col-md-12 position-relative">
                                                <label  className="form-label">Nhập vào tiêu đề</label>
                                                <input type="text" className="form-control" placeholder='Hướng dẫn cách dạy con...' onChange={(e) => setTitle(e.target.value)} defaultValue={title} />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                            <div className="col-md-12 position-relative mt-4">
                                                <label  className="form-label">Nhập vào đường dân</label>
                                                <input type="text" className="form-control" placeholder='huong-dan-cach-day-con...' onChange={(e) => setSlug(e.target.value)} defaultValue={slug} />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                            <div className="col-md-12 position-relative mt-4">
                                                <label  className="form-label">Nhập vào đường dân</label>
                                                <ReactQuill style={{height: '700px'}} theme="snow" value={content} onChange={setContent} 
                                                modules={
                                                    {
                                                        toolbar: [
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                        ['link', 'image'],
                                                        ['clean']
                                                        ]
                                                    }
                                                    
                                                }
                                                
                                                formats={
                                                    [
                                                        'header',
                                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                        'list', 'bullet', 'indent',
                                                        'link', 'image'
                                                    ]
                                                }
                                                />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                        </fieldset>
                                    }
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        loading === false && <fieldset>
                                            <legend className="title-legend">SEO</legend>
                                            <hr/>

                                            <div className="col-md-12 position-relative">
                                                <label  className="form-label">Nhập vào tiêu đề</label>
                                                <input type="text" className="form-control" placeholder='Hướng dẫn cách dạy con...' onChange={(e) => {setTitleSeo(e.target.value); }} defaultValue={titleSeo} />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                            
                                            <div className="col-md-12 position-relative mt-4">
                                                <label  className="form-label">Nhập vào giới thiệu ngắn</label>
                                                <textarea className="form-control" rows="5" placeholder='Giới thiệu ngắn về bài viết' onChange={(e) => {setDescriptionSeo(e.target.value); }} defaultValue={descriptionSeo}></textarea>
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>

                                        </fieldset>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="card">
                                <div className="card-body">

                                    {
                                        loading === false && <div>
                                            {
                                                thumbnails !== '' && <UploadButton idName="image-thumbnails" path='images/post' images={[thumbnails]} title='Ảnh đại diện' value={setThumbnails} />
                                            } 

                                            <hr className='mt-2'/>
                                            <div className="col-md-12 position-relative">
                                                <label  className="form-label">Danh mục</label>
                                                <select className="form-select" onChange={(e) => setCategoryId(e.target.value)} check={categoryId} defaultValue={categoryId}>
                                                    {
                                                        category[0] && category.map((d, i) => 
                                                            <option selected={categoryId === d.id && true} value={d.id}>{d.name_school_category}</option>
                                                        )
                                                    }
                                                </select>
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                            <hr className='mt-2'/>

                                            <div className="col-md-12 position-relative mt-4">
                                                <label  className="form-label">Độ ưu tiên</label>
                                                <input type="number" className="form-control" placeholder='10000' min={1} onChange={(e) => setOrder(e.target.value)} defaultValue={order} />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>

                                            <div className="col-md-12 position-relative  mt-4">
                                                <label  className="form-label">Nhập vào thẻ</label>
                                                <input type="text" className="form-control" placeholder='tìm trường, chính sách, hướng dẫn, ...' onChange={(e) => setTags(e.target.value)} defaultValue={tags} />
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default View;


import Logo from './assets/images/logo.svg';
import { Outlet} from "react-router-dom";

const Out = () => {
    return (<div className="wrapper bg-login">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div className="col mx-auto">
                        <div className="mb-4 text-center">
                            <img src={Logo} width="180" alt="" />
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
};

export default Out;
import React , { useState } from 'react';
import { useAlert } from 'react-alert';
import $ from 'jquery';
import axios from 'axios';
import {Link} from 'react-router-dom';

const Signup = () => {
    const alert = useAlert();
    const [lastName , setLastName] = useState('');
    const [firstName , setFirstName] = useState('');
    const [userName , setUserName] = useState('');
    const [email , setEmail] = useState('');
    const [phone , setPhone] = useState('');
    const [password , setPassword] = useState('');
    const [confirmPassword , setConfirmPassword] = useState('');
    const [flexSwitchCheckChecked , setFlexSwitchCheckChecked] = useState(0);

    const HandleClose = () => {
        setLastName('');
        setFirstName('');
        setUserName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setConfirmPassword('');
        setFlexSwitchCheckChecked('');
    }
    
    const HanldRegister = () => {
        $('#HanldRegister').text('Đang gửi ...');
        if (flexSwitchCheckChecked !== 1) {
            $('#HanldRegister').text('Đăng ký');
            return alert.info('Bạn cần tham khảo chính sách của chúng tôi.');
        }
        axios.post(localStorage.getItem('url_api')+'api/auth/register',
        {
            "email" : email,
            "user_name" : userName,
            "phone" : phone,
            "first_name" : firstName,
            "last_name" : lastName,
            "password" : password,
            "confirm_password" : confirmPassword,
            "homepage" : localStorage.getItem('homepage')
        },
        {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            $('#HanldRegister').text('Đăng ký');
            HandleClose();
            return alert.success(response.data.message);
        })
        .catch(result => { 
            if (result['response']['data'].constructor === String) {
                var data = JSON.parse(result['response']['data']);
            }

            $('#HanldRegister').text('Đăng ký');
            for (const [key, value] of Object.entries(data)) {
                alert.info(value);
            }
        });
    }

    const showPassword = (event) => {
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") == "text") {
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass("bx-hide");
            $('#show_hide_password i').removeClass("bx-show");
        } else if ($('#show_hide_password input').attr("type") == "password") {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass("bx-hide");
            $('#show_hide_password i').addClass("bx-show");
        }
    }
    return <div className="card">
    <div className="card-body">
        <div className="border p-4 rounded">
            <div className="text-center">
                <h3 className="">Đăng ký</h3>
                <p>Bạn đã có tài khoản ? <Link to="/login">Đăng nhập</Link>
                </p>
            </div>
            <div className="form-body">
                <form className="row g-3">
                    <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">Tên</label>
                        <input type="text" className="form-control" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} id="lastName" placeholder="Nhập vào tên"/>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label">Họ</label>
                        <input type="text" className="form-control" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} id="firstName" placeholder="Nhập vào họ"/>
                    </div>
                    <div className="col-4">
                        <label htmlFor="userName" className="form-label">Tài khoản</label>
                        <input type="text" className="form-control" onChange={(e) => setUserName(e.target.value)} defaultValue={userName} id="userName" placeholder="Nhập vào tài khoản"/>
                    </div>
                    <div className="col-4">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} defaultValue={email} id="email" placeholder="Nhập vào email"/>
                    </div>
                    <div className="col-4">
                        <label htmlFor="phone" className="form-label">Điên thoại</label>
                        <input type="number" className="form-control" onChange={(e) => setPhone(e.target.value)} defaultValue={phone} id="phone" placeholder="Nhập vào điện thoại"/>
                    </div>
                    <div className="col-6">
                        <label htmlFor="setPassword" className="form-label">Mật khẩu</label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" className="form-control border-end-0" onChange={(e) => setPassword(e.target.value)} defaultValue={password} id="setPassword" placeholder="Nhập mật khẩu"/> 
                            <a onClick={showPassword} event="true" className="input-group-text bg-transparent" href={this}>
                                <i className='bx bx-hide'></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="confirmPassword" className="form-label">Mật khẩu xác nhận</label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" className="form-control border-end-0" id="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)} defaultValue={confirmPassword} placeholder="Nhập mật khẩu xác nhận"/> 
                            <a onClick={showPassword} event="true" className="input-group-text bg-transparent" href={this}>
                                <i className='bx bx-hide'></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" checked={flexSwitchCheckChecked == 1?'checked':''} onChange={(e) => setFlexSwitchCheckChecked(flexSwitchCheckChecked == 1? 0 : 1)} value={1} id="flexSwitchCheckChecked"/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Tôi đã đọc  
                        <Link className="text-center" to={'/'}> chính sách bảo mật</Link> & đồng ý chính sách trên</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="d-grid">
                            <button type="button" onClick={() => HanldRegister()} id="HanldRegister" className="btn btn-primary">Đăng ký</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
}
export default Signup;
import React, { useState, useEffect } from 'react';
import UploadButton from '../../media/upload/UploadButton';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useAlert } from 'react-alert';

const View = () => {
    const alert = useAlert();
    const [formValues, setFormValues] = useState([{ name: '', star : '', content: '', image: ''}]);
    const [loading, setLoading] = useState(false);

    let handleChange = (i, e, n = '') => {
        console.log('Phương đang test; '+e);
        let newFormValues = [...formValues];
        if(n !== '') {
            newFormValues[i][n] = e;
        } else {
            newFormValues[i][e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { name: '', star : '', content: '', image: ''}])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    useEffect(() => {
        getData('khachhang_home');
    }, []);
    
    const getData = async (key) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : key
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var value = JSON.parse(response.data.value);
            setFormValues(value);
            setLoading(true);

        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return (
        <form  onSubmit={updateSetting}>
          {formValues.map((element, index) => (
            <div className='border border-danger mt-1 rounded bg-light p-1'>
                <div className="form-inline row" key={index}>
                    <div className="col-2">
                        <label>Hình ảnh</label>
                        {
                            loading && <UploadButton idName={'image-'+index} path='images/home' images={[element.image]} title='Chọn hình' value={e => handleChange(index, e, 'image')} />
                        }
                    </div>
                    <div className="col-3">
                        <label>Tên</label>
                        <input type="text" className="form-control" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} />
                    </div>
                    <div className="col-1">
                        <label>Số sao</label>
                        <input type="number" min={1} max={5} className="form-control" name="star" value={element.star || ""} onChange={e => handleChange(index, e)} />
                    </div>
                    <div className="col-5">
                        <label>Nội dung</label>
                        <textarea type="text" rows={5} className="form-control" name="content" value={element.content || ""} onChange={e => handleChange(index, e)} ></textarea>
                    </div>
                    <div className="col-1 pt-4">
                        {
                            index ? 
                            <button type="button"  className="button remove btn btn-sm btn-dark" onClick={() => removeFormFields(index)}><i className='bx bx-trash'></i></button> 
                            : null
                        }
                    </div>
                </div>
            </div>
          ))}
          <div className="button-section mt-3">
                <button className="mt-1 button add btn btn-sm btn-dark" type="button" onClick={() => addFormFields()}>Thêm</button>
                <button type='button' className='mt-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'khachhang_home', value : formValues})}>Lưu</button>
          </div>
      </form>
    )
}

export default View;
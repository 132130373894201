import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import parse from 'html-react-parser';
import $ from 'jquery';
import ReactQuill from 'react-quill';
import AsyncSelect  from "react-select/async";
import { useAlert } from 'react-alert';
import 'react-quill/dist/quill.snow.css';

const Content = () => {
    const alert = useAlert();
    const [tableData, setTableData] = useState([]);
    const { id } = useParams();
    const [category, setCategory] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [idContent, setIdContent] = useState(null);
    const [isActived, setIsActived] = useState(null);
    const [typeCategory, setTypeCategory] = useState('');
    const [typeCategoryList, setTypeCategoryList] = useState([]);
    const [typeCategoryFull, setTypeCategoryFull] = useState([]);
    const [extensionChose, setExtensionChose] = useState([]);
    const [extension, setExtension] = useState([]);
    const [typeCategoryCheck, setTypeCategoryCheck] = useState([]);
    const [urlApi] = useState(localStorage.getItem('url_api'));

    const getData = async () => {
        setTableData('');
        axios.post(localStorage.getItem('url_api')+'api/auth/school/content', {
            id : id,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTableData(response.data.data);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/category/view', {
            page : 1,
            limit : 1000,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            const options = [];
            response.data.data.map((u, i) => 
                options[u.slug_school_category] = u.name_school_category 
            );
            setCategory(options);

            let optionsUtilities =  [];
            let optionsInfrastructure = [];
            let optionsUtilitiesCheck =  [];
            let optionsInfrastructureCheck = [];
            let typeCategoryFull = [];
            response.data.data.map((c, i) => {
                c.type === 'utilities' && optionsUtilities.push({ value : c.slug_school_category, label: c.name_school_category});
                c.type === 'infrastructure' && optionsInfrastructure.push({ value : c.slug_school_category, label: c.name_school_category});
                c.type === 'utilities' && optionsUtilitiesCheck.push(c.slug_school_category);
                c.type === 'infrastructure' && optionsInfrastructureCheck.push(c.slug_school_category); 
                typeCategoryFull[c.slug_school_category] = c.name_school_category;
            });
            setTypeCategoryList({ utilities: optionsUtilities, infrastructure : optionsInfrastructure});
            setTypeCategoryCheck({ utilities: optionsUtilitiesCheck, infrastructure : optionsInfrastructureCheck});
            setTypeCategoryFull(typeCategoryFull);
        });
    }

    const setDataUpdateAddField = async (d) => {
        setIdContent(d.id)
        setTitle(d.title);
        setContent(d.content);
        setIsActived(d.is_actived);
        let extensionOption = JSON.parse(d.extension);
        setExtension(extensionOption);
        if(extensionOption !== []) {
            if(typeCategoryCheck.utilities.includes(extensionOption[0])) {
                setTypeCategory('utilities');
            }
            if(typeCategoryCheck.infrastructure.includes(extensionOption[0])) {
                setTypeCategory('infrastructure');
            }
            
            let extensionChose = [];
            extensionOption.map( (u, i) => extensionChose.push({ value : u, label: typeCategoryFull[u] }) );
            setExtensionChose(extensionChose);
        }
    }

    const DataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <div key={i} className="card card-content">
                <div className={d.type === 'post'? 'card-header bg-success' : 'card-header'}>
                    <h3 style={{display: 'flex', alignItems: 'center'}}>{d.title} &nbsp; <span style={{fontSize: '15px', color: 'red'}}>{d.is_actived ? '(Đang hoạt động)' : '(Đang ẩn)'}</span></h3>
                </div>
                <div className="card-body">
                    <div className='extension'>
                        <ul className='nav'>
                            {
                                JSON.parse(d.extension).map((e, j) => 
                                    <li key={j} className='bg-info boder m-1 p-1 text-white'>{category[e]}</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className='content'>
                        {parse(d.content)}
                    </div>
                </div>
                <div className="card-footer text-end">
                    <div className='btn-group'>
                        <button className='btn btn-sm btn-dark text-center' onClick={() => setIdContent(d.id)} data-bs-toggle="modal" data-bs-target="#btnRemove">
                            <i className='bx bx-trash'></i>
                        </button>
                        <button className='btn btn-sm btn-primary text-center' onClick={() => setDataUpdateAddField(d)} data-bs-toggle="modal" data-bs-target="#btnAdd">
                            <i className='bx bx-edit'></i>
                        </button>
                    </div>
                </div>
            </div>
        );

        return content;
    }

    const contentRemove = (idRemove) => {
        if(idRemove == null) {
            return alert.error('Cần nhập vào nội dung trường bạn cần xóa');
        }

        axios.post(urlApi+'api/auth/school/content_remove', {
            id : idRemove,
        },
        {
            headers: {
            'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
            'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData();
            if(response.data.success === true) {
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const loadOptions = (
        inputValue: string,
        callback: (options: typeCategoryList[typeCategory]) => void
      ) => {
        setTimeout(() => {
          callback(filterColors(inputValue));
        }, 1000);
    };

    const filterColors = (inputValue: string) => {
        return typeCategoryList[typeCategory].filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const contentUpdate = () => {
        if(title == null) {
            return alert.error('Cần nhập vào nội dung trường bạn cần xóa');
        }

        let extensionSend = [];
        if(extension[0]) {
            if(extension[0].value){
                extension.map((v, i) => extensionSend.push(v.value));
            } else {
                extension.map((v, i) => extensionSend.push(v));
            }
        }

        axios.post(urlApi+'api/auth/school/content_update', {
            id : idContent,
            title : title,
            content : content,
            extension : extensionSend,
            is_actived : isActived,
            school_id : id,
            type : 'info',
        },
        {
            headers: {
            'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
            'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData();
            if(response.data.success === true) {
                contentRefresh();
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        });
    }

    const contentRefresh = () => {
        setIdContent('');
        setTitle('');
        setContent('');
        setTypeCategory('');
        setIsActived(0);
        setExtension([]);
        document.getElementById("form").reset();
    }

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Đánh giá</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#btnAdd">
                    <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        
                        <div className='row'>
                            <div className='col-6'>
                                <h6 className="mb-0 text-uppercase">Nội dung thông tin trường</h6>
                            </div>
                        </div>
                        <hr/>

                        <div className='col-md-6 mx-auto'>
                            {tableData && <DataBody tableData={tableData} />}
                        </div>

                    </div>

                </div>
            </div>

            <div className="modal fade" id="btnAdd"  data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Cập nhật nội dung</h4>
                        <button type="button" onClick={() => contentRefresh()}  className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">

                        <form id='form'>
                            <div className="form-group input-group-sm">
                                <div className='row'>
                                    <div className='col-12'>
                                        <label htmlFor="pwd">Tiêu đề: <b className="text-danger">(*)</b></label>
                                        <input onChange={(e) => setTitle(e.target.value)} defaultValue={title} type="text" className="form-control"/>
                                    </div>
                                    <div  className="col-12 mt-3 add-content-school">
                                        <select className="form-control"  onChange={(e) => {setTypeCategory(e.target.value); setExtension([])}} valueText={typeCategory} defaultValue={typeCategory}>
                                            <option>Dối tượng không được mở rộng</option>
                                            <option selected={(typeCategory === 'infrastructure') ? true : false} value={'infrastructure'}>Cơ sở vật chất</option>
                                            <option selected={(typeCategory === 'utilities') ? true : false} value={'utilities'}>Tiện ích</option>
                                        </select>
                                    </div>
                                    {
                                        (typeCategory === 'infrastructure' || typeCategory === 'utilities') && 
                                        <div  className="col-12 mt-3 add-content-school">
                                            <label htmlFor="usr">Mở rộng: <b className="text-danger">(*)</b></label>
                                            <AsyncSelect onChange={(e) => setExtension(e) } isMulti placeholder={(typeCategory === 'infrastructure') ? 'Cơ sở vật chất' : 'Tiện ích'} cacheOptions loadOptions={loadOptions} defaultOptions defaultValue={extensionChose}/>
                                        </div>
                                    }
                                    <div className="col-12 mt-3 add-content-school">
                                        <input type={'checkbox'} defaultValue={isActived} checked={isActived === 1 ? true : false} onChange={() =>  setIsActived(isActived === 1 ? 0 : 1)} id={'is_actived'}/> &nbsp;
                                        <label htmlFor="is_actived">Hiển thị nội dung này trong bài viết của trang trường<b className="text-danger"> (*)</b></label>
                                    </div>
                                    <div className="col-12 mt-3 add-content-school">
                                        <label htmlFor="usr">Nội dung: <b className="text-danger">(*)</b></label>
                                        <ReactQuill style={{height: '300px'}} theme="snow" value={content} onChange={setContent} 
                                            modules={
                                                {
                                                    toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link', 'image'],
                                                    ['clean']
                                                    ]
                                                }
                                                
                                            }
                                            
                                            formats={
                                                [
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]
                                            }
                                            />
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => contentRefresh()} className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                        <button type="button" onClick={() => contentUpdate()} className="btn btn-primary" data-bs-dismiss="modal">Lưu</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* Xóa bỏ đói tượng  */}
            <div className="modal fade" id="btnRemove"  onClick={() => setIdContent(null)} data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Xóa</h4>
                        <button type="button"  className="btn-close"  data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> nội dung này không ?</p>
                    </div>

                    <div className="modal-footer">
                        <button type="button"  className="btn btn-secondary" onClick={() => setIdContent(null)}  data-bs-dismiss="modal">Hủy</button>
                        <button type="button"  className="btn btn-primary" onClick={() => contentRemove(idContent)}  data-bs-dismiss="modal">Xóa</button>
                    </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Content;
import React , { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
// import validator from 'validator';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useAlert } from 'react-alert';

const View = () => {
    const alert = useAlert();
    const [tableData, setTableData] = useState([]);
    const [countData, setCountData] = useState(1);
    const [isActived, setIsActived] = useState(1);
    const [limit, setLimit] = useState(25);
    const [idRemove, setIdRemove] = useState('');
    const [titleSearch, setTitleSearch] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [category, setCategory] = useState([]);
    const [urlApi] = useState(localStorage.getItem('url_api'));
    let [page, setPage] = useState(1);

    const getData = async (page) => {
        setTableData('');
        axios.post(urlApi+'api/auth/post/view', {
            title : titleSearch,
            category_id : categoryId,
            is_actived : isActived,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTableData(response.data.data);
            setCountData(response.data.count);
            setCategory(response.data.category);
        });

    }

    const reSet = async () => {
        setTitleSearch('');
        setCategoryId('');
        setIsActived('');
        setLimit(25);
        document.getElementById("filter-form").reset();
    }

    const TableDataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <tr key={i}>
                <td className='text-center align-middle' style={{width: '0.5%'}}>{i+1}</td>
                <td className='align-middle' style={{width: '5%'}}>
                    <i style={{fontSize: '14px'}} className={d.is_confirm == 1 ? 'bx bx-badge-check text-success' : ''}></i>
                    <a href={ urlApi+'bai-viet/'+d.slug } target="_blank">{d.title}</a>
                </td>
                <td className="text-center align-middle">{d.category_name}</td>
                <td className="text-center align-middle">{d.created_user_name}</td>
                <td className="text-center align-middle">{d.created_at}</td>
                <td className="text-center align-middle">{d.see}</td>
                <td className="text-center align-middle font-22"><i className={ d.is_actived === 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                <td style={{width: '5%'}} className="text-center align-middle">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={'/post/edit/'+d.id} className="btn btn-success ml-1 btn-sm" title="Sửa"><i className="bx bx-edit"></i></Link>
                        <button type="button" onClick={() => setIdRemove(d.id)} className="btn btn-primary btn-sm ml-1" title="Xóa" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );

        return content;
    }

    const handleRemove = () => {
        if(idRemove == null) {
           return alert.error('Cần nhập vào danh mục bạn cần xóa');
        }

        axios.post(urlApi+'api/auth/post/remove', {
            id : idRemove,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData(page);
            if(response.data.success === true) {
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        })
    }

    const SelectOption = (props) => {
        const content = Object.keys(props.data).map((t, i) =>
            <option key={i} value={t}>{category[t]}</option>
        );

        return content;
    }

    useEffect(() => {
        getData(page);
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Danh mục</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#btnFilter">
                    <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button>
                    <Link to="/post/add" className="mx-1 btn btn-success btn-sm">
                    <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Danh mục</h6>
                        </div>
                        <div className='col-6 text-end'>
                            <span className="mb-0">Trang hiện thời là {page}/{Math.ceil(countData / limit)}. Có tổng {countData} danh mục</span>
                        </div>
                    </div>
                    <hr/>

                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="example" className="table table-striped table-bordered" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th className="text-center">STT</th>
                                            <th className="text-center">Hình ảnh</th>
                                            <th className="text-center">Tên danh mục</th>
                                            <th className="text-center">Tác giả</th>
                                            <th className="text-center">Thời gian viết bài</th>
                                            <th className="text-center">Lượt xem</th>
                                            <th className="text-center font-22">
                                                <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                <i className='text-warning bx bx-checkbox'></i>
                                            </th>
                                            <th className="text-center">Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            
                                        {
                                            tableData ? <TableDataBody tableData={tableData}/> :  <tr>
                                                <td className='text-center' colSpan={8}> 
                                                    <div className="spinner-border spinner-border-sm text-danger" role="status"> 
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th className="text-center">STT</th>
                                            <th className="text-center">Hình ảnh</th>
                                            <th className="text-center">Tên danh mục</th>
                                            <th className="text-center">Tác giả</th>
                                            <th className="text-center">Thời gian viết bài</th>
                                            <th className="text-center">Lượt xem</th>
                                            <th className="text-center font-22">
                                                <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                <i className='text-warning bx bx-checkbox'></i>
                                            </th>
                                            <th className="text-center">Cập nhật</th>
                                        </tr>
                                    </tfoot>
                                </table>

                                <PaginationControl
                                    page={page}
                                    between={4}
                                    total={countData}
                                    limit={limit}
                                    changePage={(page) => {
                                        setPage(page); 
                                        console.log(page);
                                        getData(page);
                                        window.scrollTo(0,0);
                                    }}
                                    ellipsis={1}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* form tìm kiếm */}
            <div className="modal fade" id="btnFilter" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title text-sm">Lọc</h4>
                            <button type="button"  className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form method='POST' id="filter-form">
                            <div className="form-group input-group-sm">
                                <label htmlFor="usr">Tên bài viết: <b className="text-danger"></b></label>
                                <input onChange={(e) => setTitleSearch(e.target.value)} defaultValue={titleSearch} type="text" className="form-control"/>
                            </div>
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                <select className="form-control" value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                                    <option value={''}>== Chọn loại danh mục ==</option>
                                    <SelectOption data={category}/>
                                </select>
                            </div>
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                <select className="form-control" value={isActived} onChange={(e) => setIsActived(e.target.value)}>
                                    <option value={''}>== Trạng thái ==</option>
                                    <option value={0}>Đã ẩn</option>
                                    <option value={1}>Đang hoạt động</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => reSet()} className="btn btn-primary btn-sm">Xóa</button>
                        <button type="button" onClick={() => { setPage(1); getData(1)}} className="btn btn-success btn-sm" data-bs-dismiss="modal">Lọc</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* xóa bỏ đổi tượng */}
            <div className="modal fade" id="myModalRemove" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Xác nhận xóa bỏ</h4>
                            <button type="button" onClick={() => setIdRemove('')} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> bài viết này không ?</p>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => setIdRemove('')} className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Hủy</button>
                        <button type="button" onClick={() => handleRemove()} className="btn btn-primary btn-sm" data-bs-dismiss="modal">Xóa</button>
                    </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default View;
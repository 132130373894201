import React , { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import validator from 'validator';
import Cookies from 'js-cookie';
import UploadButton from '../media/upload/UploadButton';
import AsyncSelect  from "react-select/async";
import Select from "react-select";
import $ from 'jquery';
import { useAlert } from 'react-alert';

const Edit = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const [city, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [category, setCategory] = useState([]);
    const [users, setUsers] = useState([]);
    const [isActived, setIsActived] = useState(0);
    const [isConfirm, setIsConfirm] = useState(0);
    const [nameSchool, setNameSchool] = useState('');
    const [logo, setLogo] = useState('');
    const [thumbnails, setThumbnails] = useState('');
    const [images, setImages] = useState('');
    const [fullAddress, setFullAddress] = useState('');
    const [map, setMap] = useState('');
    const [typeSchool, setTypeSchool] = useState('');
    const [subDomain, setSubDomain] = useState('');
    const [slugSchool, setSlugSchool] = useState('');
    const [idDistrict, setIdDistrict] = useState('');
    const [idCity, setIdCity] = useState('');
    const [idSchoolCategory, setIdSchoolCategory] = useState('');
    const [ageBegin, setAgeBegin] = useState('');
    const [ageEnd, setAgeEnd] = useState('');
    const [ageBeginType, setAgeBeginType] = useState('');
    const [ageEndType, setAgeEndType] = useState('');
    const [tuitionType, setTuitionType] = useState('');
    const [tuitionBegin, setTuitionBegin] = useState('');
    const [tuitionEnd, setTuitionEnd] = useState('');
    const [order, setOrder] = useState(0);
    const [facebook, setFacebook] = useState('');
    const [messenger, setMessenger] = useState('');
    const [zalo, setZalo] = useState('');
    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [ofUserID, setOfUserID] = useState(0);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        // xem chi tiết trường
        axios.get(localStorage.getItem('url_api')+'api/auth/school/detail/'+id, 
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setNameSchool(response.data.school.name_school);
            setSlugSchool(response.data.school.slug_school);
            setFullAddress(response.data.school.full_address);
            setTypeSchool(response.data.school.type_school);
            setSubDomain(response.data.school.subdomain);
            setCity(response.data.school.is_city);
            setImages(JSON.parse(response.data.school.images));
            setLogo(response.data.school.logo);
            setThumbnails(response.data.school.thumbnails);
            setIdCity(response.data.school.id_city);
            setIdDistrict(response.data.school.id_district);
            setIdSchoolCategory(response.data.school.id_school_category);
            setTuitionBegin(response.data.school.tuition_begin);
            setTuitionEnd(response.data.school.tuition_end);
            setTuitionType(response.data.school.tuition_type);
            setAgeBegin(response.data.school.age_begin);
            setAgeEnd(response.data.school.age_end);
            setAgeBeginType(response.data.school.age_begin_type);
            setAgeEndType(response.data.school.age_end_type);
            setZalo(response.data.school.zalo);
            setFacebook(response.data.school.facebook);
            setMessenger(response.data.school.messenger);
            setMap(response.data.school.map);
            setOfUserID(response.data.of_user_id);
            setExpiryDate(response.data.school.expiry_date);
            setOrder(response.data.school.order);
            setPhone(response.data.school.phone);
            setIsActived(response.data.school.is_actived);
            setIsConfirm(response.data.school.is_confirm);
            setUsers(response.data.user);
            setCity(response.data.city);
            setCategory(response.data.category);
            if(response.data.seo) {
                setTitle(response.data.seo.title);
                setDescription(response.data.seo.description);
                setTags(response.data.seo.tags);
            }
            setLoading(true);
        });
    }

    const getDistrict = (parent_id) => {
        setIdDistrict(null);
        if(parent_id == null) {
            return 0;
        }
        setIdCity(parent_id); 

        axios.post(localStorage.getItem('url_api')+'api/auth/address/get', {
            parent_id: parent_id,
            type: 'district'
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setDistrict(response.data.local);
        });
    }

    const updateData = () => {
        let user_id = '';
        if(ofUserID !== [] || ofUserID === null ) {
           user_id = Object.values(ofUserID)[0];
        }

        
        $('.invalid-feedback').removeClass('d-block');
        $('.invalid-feedback').html('');

        axios.post(localStorage.getItem('url_api')+'api/auth/school/edit', {
            'id' : id, 
            'name_school' : nameSchool, 
            'logo' : logo, 
            'thumbnails' : thumbnails, 
            'images' : images, 
            'full_address' : fullAddress, 
            'map' : map, 
            'type_school' : typeSchool, 
            'subdomain' : subDomain, 
            'slug_school' : slugSchool, 
            'id_district' : idDistrict, 
            'id_city' : idCity, 
            'id_school_category' : idSchoolCategory, 
            'age_begin' : ageBegin, 
            'age_end' : ageEnd, 
            'age_begin_type' : ageBeginType, 
            'age_end_type' : ageEndType, 
            'tuition_type' : tuitionType, 
            'tuition_begin' : tuitionBegin, 
            'tuition_end' : tuitionEnd, 
            'expiry_date' : expiryDate, 
            'order' : order, 
            'facebook' : facebook, 
            'messenger' : messenger, 
            'zalo' : zalo, 
            'phone' : phone, 
            'of_user_id' : user_id,
            'is_actived' : isActived,  
            'is_confirm' : isConfirm,
            'title' : title,  
            'description' : description,  
            'tags' : tags,  
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            if(response.data.success) {
                alert.success(response.data.message);
            } else {
                alert.info(response.data.message);
            }
        }).catch(function (error) {
           
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            var arrError = error.response.data;
            if (arrError.constructor === String) {
                arrError = JSON.parse(arrError);
            }

            for (const key in arrError) {
                if (Object.hasOwnProperty.call(arrError, key)) {
                    const element = arrError[key];
                    let htmlP = '';
                    element.map((k, v) => htmlP += '<p>'+k+'</p>');
                    $('.feedback-'+key).html(htmlP);
                    $('.feedback-'+key).addClass('d-block');
                }
            }

        });
    }

    const loadOptions = (
        inputValue: string,
        callback: (options: users) => void
      ) => {
        setTimeout(() => {
          callback(filterUsers(inputValue));
        }, 1000);
    };

    const filterUsers = (inputValue: string) => {
        return users.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    useEffect(() => {
        // get data
        getData();
        getDistrict(idCity);
        
    }, []);

    return (
	    <div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={'/school/view'}>Trường học</Link>
                            </li>
                            <li className="breadcrumb-item active">{nameSchool ? nameSchool : 'Chi tiết trường'}</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-warning btn-sm text-white me-1">
                        <i className="bx bx-arrow-back text-white"></i> Trở lại
                    </button>
                    <button className="btn btn-primary btn-sm" onClick={() => updateData()} type="submit"><i className="bx bx-save text-white"></i> Lưu trường</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Thêm trường học</h6>
                        </div>
                        <div className='col-6 text-end'>
                        </div>
                    </div>
                    <hr/>

                        <div className="row g-3 needs-validation">
                            <div className="col-md-3 position-relative">

                                <div className="card">
                                    <div className="card-body">
                                        <div className='p-4 border rounded'>
                                            {
                                                loading && <UploadButton idName="image-logo" path='images/kids' images={[logo]} title='Chọn logo' value={setLogo} />
                                            }
                                            {
                                               loading && <UploadButton idName="image-thumbnails" path='images/kids' images={[thumbnails]} title='Ảnh đại diện' value={setThumbnails} />
                                            }
                                            {
                                               loading && <UploadButton multiple='true' idName="image-images" path='images/kids' images={images} title='Thư viện ảnh' value={setImages} />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className="col-md-6 position-relative">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='p-4 border rounded'>
                                            
                                            {
                                                loading && <fieldset className='row'>
                                                <legend className="title-legend">THÔNG TIN TRƯỜNG</legend>
                                                <hr/>

                                                <div className="col-md-12 position-relative">
                                                    <label  className="form-label">Tên trường</label>
                                                    <input type="text" className="form-control" placeholder='Tên trường Nguyễn Văn A...' onChange={(e) => {
                                                        setNameSchool(e.target.value); 
                                                    } } defaultValue={nameSchool} />
                                                    <div className="invalid-feedback feedback-name_school">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-12 position-relative mt-4">
                                                    <label  className="form-label">Đường dẫn</label>
                                                    <input type="text" className="form-control" placeholder='ten-truong-nguyen-van-a' onChange={(e) => {setSlugSchool(e.target.value)}} defaultValue={slugSchool} />
                                                    <div className="invalid-feedback feedback-slug_school">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-12 position-relative mt-4">
                                                    <label  className="form-label">Địa chỉ trường</label>
                                                    <input type="text" className="form-control" placeholder='Số 123, đường số 1, phường ABC...' onChange={(e) => setFullAddress(e.target.value)} defaultValue={fullAddress} />
                                                    <div className="invalid-feedback feedback-full_address">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-6 position-relative mt-4">
                                                    <label  className="form-label">Loại trường</label>
                                                    <select className="form-select" onChange={(e) => setTypeSchool(e.target.value)} defaultValue={typeSchool} >
                                                        <option value="">== Loại trường ==</option>
                                                        <option value="song-ngu">Song ngữ</option>
                                                        <option value="cong-lap">Công lập</option>
                                                        <option value="tu-thuc">Tư thục</option>
                                                        <option value='quoc-te'>Quốc tế</option>
                                                    </select>
                                                    <div className="invalid-feedback  feedback-type_school">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-6 position-relative mt-4">
                                                    <label  className="form-label">Danh mục</label>
                                                    <select className="form-select" value={idSchoolCategory} onChange={(e) => setIdSchoolCategory(e.target.value)} defaultValue={idSchoolCategory}>
                                                        {
                                                            category.map((d, i) => 
                                                                <option key={i} value={d.id}>{d.name_school_category}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback  feedback-id_school_category" >Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-4 position-relative mt-4">
                                                    <label  className="form-label">Học phí từ</label>
                                                    <div className="input-group">
                                                        <input type="number" min={1}  className="form-control" placeholder='1000000000' onChange={(e) => setTuitionBegin(e.target.value)} defaultValue={tuitionBegin} />
                                                        <span className="input-group-text">đ</span>
                                                    </div>
                                                    <div className="invalid-feedback feedback-tuition_begin">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-4 position-relative mt-4">
                                                    <label  className="form-label">Học phí đến</label>
                                                    <div className="input-group">
                                                        <input type="number" min={1} className="form-control" placeholder='5000000000' onChange={(e) => setTuitionEnd(e.target.value)} defaultValue={tuitionEnd} />
                                                        <span className="input-group-text">đ</span>
                                                    </div>
                                                    <div className="invalid-feedback feedback-tuition_end">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-4 position-relative mt-4">
                                                    <label  className="form-label">Khóa/tháng/năm</label>
                                                    <select className="form-select" id='tuitionType' onChange={(e) => setTuitionType(e.target.value)} defaultValue={tuitionType}>
                                                        <option value="">Chọn khóa/tháng/năm</option>
                                                        <option value="khoa">Khóa</option>
                                                        <option value="thang">Tháng</option>
                                                        <option value="nam">Năm</option>
                                                    </select>
                                                    <div className="invalid-feedback feedback-tuition_type">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-9 position-relative mt-4">
                                                    <label htmlFor="phone" className="form-label">Điện thoại</label>
                                                    <input type="text" placeholder='0912345678' className="form-control" id='phone' onChange={(e) => setPhone(e.target.value)} defaultValue={phone} />
                                                    <div className="invalid-feedback feedback-phone">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-3 position-relative mt-4">
                                                    <label  className="form-label">Điểm ưu tiên</label>
                                                    <input type="number" min={0} max={1000000} className="form-control" id='order' onChange={(e) => setOrder(e.target.value? e.target.value : 0)} defaultValue={order && 0} />
                                                    <div className="invalid-feedback feedback-order">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-12 position-relative mt-4">
                                                    <label htmlFor="facebook" className="form-label">Facebook</label>
                                                    <input type="text" placeholder='https://facebook.com/abc...' className="form-control" id='facebook' onChange={(e) => setFacebook(e.target.value)} defaultValue={facebook} />
                                                    <div className="invalid-feedback  feedback-facebook">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-12 position-relative mt-4">
                                                    <label htmlFor="messenger" className="form-label">Messenger</label>
                                                    <input type="text" placeholder='https://messenger.com/123...' className="form-control" id='messenger' onChange={(e) => setMessenger(e.target.value)} defaultValue={messenger} />
                                                    <div className="invalid-feedback feedback-messenger">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-12 position-relative mt-4">
                                                    <label htmlFor="zalo" className="form-label">Zalo</label>
                                                    <input type="text" placeholder='https://zalo.me/0912345678' id='zalo' className="form-control"  onChange={(e) => setZalo(e.target.value)} defaultValue={zalo} />
                                                    <div className="invalid-feedback feedback-zalo">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-5 position-relative mt-4">
                                                    <label htmlFor="expiryDate" className="form-label">Ngày hết hạng</label>
                                                    <input type="datetime-local" id='expiryDate' className="form-control" onChange={(e) => setExpiryDate(e.target.value)} defaultValue={expiryDate}/>
                                                    <div className="invalid-feedback feedback-expiry_date">Cần nhập vào nội dung.</div>

                                                    <label htmlFor="ofUserID" className="form-label mt-4">Chủ trường</label>
                                                    {
                                                        <AsyncSelect onChange={(e) => setOfUserID(e) } placeholder={'Chủ trường'} cacheOptions loadOptions={loadOptions} defaultOptions defaultValue={ofUserID} />
                                                    }
                                                    &nbsp;
                                                    <div className="invalid-feedback feedback-of_user_id">Cần nhập vào nội dung.</div>
                                                </div>
                                                <div className="col-md-7 position-relative mt-4">
                                                    <label htmlFor="map" className="form-label">Bản đồ</label>
                                                    <textarea className="form-control" id='map' placeholder='https://www.google.com/maps/embed?pb=!1m18!1...' onChange={(e) => setMap(e.target.value)} defaultValue={map} rows="5" name="text"></textarea>
                                                </div>

                                            </fieldset>
                                                
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className='p-4 border rounded'>
                                            {
                                                loading && 
                                                <fieldset className="row">
                                                    <legend className="title-legend">SEO</legend>
                                                    <hr/>
                                                    <div className="col-md-12 position-relative">
                                                        <label  className="form-label">Tiêu đề</label>
                                                        <input type="text" className="form-control" placeholder='Nhập tiêu đề' onChange={(e) => setTitle(e.target.value)} defaultValue={title} />
                                                    </div>
                                                    <div className="col-md-12 position-relative mt-4">
                                                        <label htmlFor="description" className="form-label">Miêu tả</label>
                                                        <textarea className="form-control" id='description' placeholder='Nhập miêu tả' onChange={(e) => setDescription(e.target.value)} defaultValue={description} rows="5" id="description" name="text"></textarea>
                                                    </div>
                                                    <div className="col-md-12 position-relative mt-4">
                                                        <label htmlFor="description" className="form-label">Thẻ</label>
                                                        <textarea className="form-control" id='description' placeholder='Nhập thẻ' onChange={(e) => setTags(e.target.value)} defaultValue={tags} rows="2" id="description" name="text"></textarea>
                                                    </div>
                                                </fieldset>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 position-relative">

                                <div className="card">
                                    <div className="card-body">
                                        <div className='p-3 border rounded'>
                                            
                                            {
                                                loading && 
                                                <div className='row'>

                                                    <div className="col-md-12 position-relative">
                                                        <label  className="form-label">Subdomain</label>
                                                        <div className="input-group">
                                                            <span className="input-group-text">https://</span>
                                                            <input type="text" className="form-control" placeholder='abc...' onChange={(e) => setSubDomain(e.target.value)} defaultValue={subDomain} />
                                                            <span className="input-group-text">.kidhub.vn</span>
                                                        </div>
                                                        <div className="invalid-feedback feedback-sub_domain">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <hr className='mt-3'/>
                                                    <div className="col-md-12 position-relative mt-2">
                                                        <p>
                                                            <input type="checkbox" checked={isActived} className="form-check-input" id='isActived' onChange={() => setIsActived(isActived === 1 ? 0: 1)} /> &nbsp;
                                                            <label htmlFor="isActived" className="form-label">Trạng thái còn hoạt động</label>
                                                        </p>
                                                        <p>
                                                            <input type="checkbox" checked={isConfirm} className="form-check-input" id='isConfirm' onChange={() => setIsConfirm (isConfirm === 1 ? 0: 1)} /> &nbsp;
                                                            <label htmlFor="isConfirm" className="form-label">Đã xác nhận</label>
                                                        </p>
                                                    </div>
                                                    <hr/>
                                                    <div className="col-md-5 position-relative">
                                                        <label  className="form-label">Nhận trẻ từ</label>
                                                        <input type="number" min={1} className="form-control"  onChange={(e) => setAgeBegin(e.target.value)} defaultValue={ageBegin} />
                                                        <div className="invalid-feedback feedback-age_begin">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <div className="col-md-7 position-relative">
                                                        <label  className="form-label">Tháng/năm</label>
                                                        <select className="form-select" value={ageBeginType} onChange={(e) => setAgeBeginType(e.target.value)} defaultValue={ageBeginType} >
                                                            <option value="">Chọn tháng/năm</option>
                                                            <option value="thang">Tháng</option>
                                                            <option value="nam">Năm</option>
                                                        </select>
                                                        <div className="invalid-feedback  feedback-age_begin_type">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <div className="col-md-5 position-relative mt-2">
                                                        <label  className="form-label">Nhận trẻ đến</label>
                                                        <input type="number" min={1} className="form-control"  onChange={(e) => setAgeEnd(e.target.value)} defaultValue={ageEnd} />
                                                        <div className="invalid-feedback feedback-age_end">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <div className="col-md-7 position-relative mt-2">
                                                        <label  className="form-label">Tháng/năm</label>
                                                        <select className="form-select" value={ageEndType} onChange={(e) => setAgeEndType(e.target.value)} defaultValue={ageEndType} >
                                                            <option value="">Chọn tháng/năm</option>
                                                            <option value="thang">Tháng</option>
                                                            <option value="nam">Năm</option>
                                                        </select>
                                                        <div className="invalid-feedback feedback-age_end_type">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <hr className='mt-3'/>
                                                    <div className="col-md-12 position-relative mt-2">
                                                        <label  className="form-label">Tỉnh/thành phố</label>
                                                        {
                                                            city && 
                                                            <select className="form-select" onChange={(e) => {getDistrict(e.target.value)}} defaultValue={idCity}>
                                                                <option value=''>== Tỉnh thành phố ==</option>
                                                                {
                                                                    city.map((d, i) => 
                                                                        <option key={i} value={d.id}>{d.address}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        }
                                                        <div className="invalid-feedback feedback-id_city">Cần nhập vào nội dung.</div>
                                                    </div>
                                                    <div className="col-md-12 position-relative mt-2">
                                                        <label  className="form-label">Quận/huyện</label>
                                                        {
                                                            district && 
                                                            <select className="form-select" onChange={(e) => setIdDistrict(e.target.value)} value={idDistrict} defaultValue={idDistrict}>
                                                                <option value={''}>== Quận huyện ==</option>
                                                                {
                                                                    district.map((d, i) => 
                                                                        <option key={i}  value={d.id}>{d.address}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        }
                                                        <div className="invalid-feedback  feedback-id_district">Cần nhập vào nội dung.</div>
                                                    </div>

                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                </div>
            </div>

        </div>
    );
}

export default Edit;
import React , { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
// import validator from 'validator';
import Cookies from 'js-cookie';
import UploadButton from '../media/upload/UploadButton';
import { useAlert } from 'react-alert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function View() {
    const alert = useAlert();
    const [imageLogo, setImageLogo] = useState([]);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [tawk, setTawk] = useState('');
    const [mienBac, setMienBac] = useState('');
    const [mienTrung, setMienTrung] = useState('');
    const [mienNam, setMienNam] = useState('');
    const [marketing1, setMarketing1] = useState([]);
    const [loadingMarketing1, setLoadingMarketing1] = useState(false);
    const [marketing2, setMarketing2] = useState([]);
    const [loadingMarketing2, setLoadingMarketing2] = useState(false);
    const [linkUpdateSchool, setLinkUpdateSchool] = useState([]);
    const [expiryDate, setExpiryDate] = useState([]);
    const [imageSeoDefault, setImageSeoDefault] = useState([]);
    const [titleSeoDefault, setTitleSeoDefault] = useState([]);
    const [contentSeoDefault, setContentSeoDefault] = useState([]);
    const [loadingSeoDefault, setLoadingSeoDefault] = useState(false);
    const [marketing1Link, setMarketing1Link] = useState('');
    const [marketing2Link, setMarketing2Link] = useState('');
    const [footer1, setFooter1] = useState('');
    const [footer2, setFooter2] = useState('');
    const [footer3, setFooter3] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [emailNotify, setEmailNotify] = useState('');
    const [facebook, setFacebook] = useState('');
    const [messenger, setMessenger] = useState('');
    const [zalo, setZalo] = useState('');
    const [post, setPost] = useState('');

    useEffect(() => {
        getLogo();
    }, []);


    // logo
    const getLogo = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'logo'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setImageLogo([response.data.value]);
            setLoadingLogo(true);
        });
    }

    // logo
    const getPosts = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'post'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setPost([response.data.value]);
        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        });
    }

    // tawk
    const getTawk = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'tawk'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTawk(response.data.value);
        });
    }

    // info
    const getInfo = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'phone'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setPhone(response.data.value);
        });
        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'address'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setAddress(response.data.value);
        });
        
        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'email'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setEmail(response.data.value);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'email_notify'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setEmailNotify(response.data.value);
        });

        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'facebook'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setFacebook(response.data.value);
        });
        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'messenger'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setMessenger(response.data.value);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'zalo'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
            
            setZalo(response.data.value);
            console.log(response.data.value);
        });
    }

    // footer
    const Footer = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'giao_vien_footer'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setFooter1(response.data.value);
        });

        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'phu_huynh_footer'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setFooter2(response.data.value);
        });

        
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'chu_truong_footer'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setFooter3(response.data.value);
        });
    }

    // link_dang_truong
    const getLinkUpdateSchool = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'link_dang_truong'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setLinkUpdateSchool(response.data.value);
        });
    }

    // expiry date
    const getExpiryDate = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'expiry_date'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setExpiryDate(response.data.value);
        });
    }

    // marketingg
    const getMarketingg = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'marketing1'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var valueM = JSON.parse(response.data.value);
            setMarketing1(valueM.images);
            setMarketing1Link(valueM.link);
            setLoadingMarketing1(true);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'marketing2'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var valueM = JSON.parse(response.data.value);
            setMarketing2(valueM.images);
            setMarketing2Link(valueM.link);
            setLoadingMarketing2(true);
        });
    }

    
    // nhóm vùng miền
    const getSeoDefault = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'seo_default'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var responseParse = [];
            if (response.data.value.constructor === String) {
                responseParse = JSON.parse(response.data.value);
            }
            setImageSeoDefault([responseParse.image]);
            setTitleSeoDefault(responseParse.title);
            setContentSeoDefault(responseParse.content);
            setLoadingSeoDefault(true);
        });
    }
    
    // nhóm vùng miền
    const getGroupFacebook = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'group_mien_bac'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setMienBac(response.data.value);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'group_mien_trung'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setMienTrung(response.data.value);
        });

        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'group_mien_nam'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setMienNam(response.data.value);
        });
    }

    return <>
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Cài đặt mở rộng</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Cài đặt mở rộng</h6>
                        </div>
                        <div className='col-6 text-end'>
                            <span className="mb-0"></span>
                        </div>
                    </div>
                    <hr/>
                    

                    <div className="card">
						<div className="card-body">
                            <ul className="nav nav-tabs nav-primary" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" onClick={() => {getLogo()}} data-bs-toggle="tab" href="#primaryhome" role="tab" aria-selected="true">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Logo</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getTawk()}} className="nav-link" data-bs-toggle="tab" href="#primaryprofile" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Tawk</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" onClick={() => {getMarketingg()}} data-bs-toggle="tab" href="#primarycontact" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Quảng cáo</div>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getGroupFacebook()}} className="nav-link" data-bs-toggle="tab" href="#primaryGroup" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Nhóm vùng miền</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getLinkUpdateSchool()}} className="nav-link" data-bs-toggle="tab" href="#linkUpdateSchool" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Đường dẫn đăng ký trường</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getExpiryDate()}} className="nav-link" data-bs-toggle="tab" href="#ExpiryDate" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Thời gian thông báo hết hạn dịch vụ</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {Footer()}} className="nav-link" data-bs-toggle="tab" href="#Footer" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Footer</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getInfo()}} className="nav-link" data-bs-toggle="tab" href="#info" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Thông tin</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getPosts()}} className="nav-link" data-bs-toggle="tab" href="#post" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Bài viết</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={() => {getSeoDefault()}} className="nav-link" data-bs-toggle="tab" href="#seoDefault" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-title">Seo mặt định</div>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content py-3">
                                <div className="tab-pane fade show active" id="primaryhome" role="tabpanel" style={{minHeight: '65vh'}}>
                                    {
                                        loadingLogo ? <UploadButton idName="logo" path='images/' images={imageLogo} title='Chọn logo' value={setImageLogo} /> : null
                                    }
                                    
                                    <button type='button' className='ms-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'logo', value : imageLogo})}>Lưu</button>
                                </div>
                                <div className="tab-pane fade" id="primaryprofile" role="tabpanel" style={{minHeight: '65vh'}}>
                                    
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Nhập vào mã tawk</label>
                                        <textarea className="form-control" id="inputAddress"  defaultValue={tawk} onChange={(e) => setTawk(e.target.value)} placeholder="Nhập vào mã tawk..." rows="5"></textarea>
                                        <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'tawk', value : tawk})}>Lưu</button>
                                    </div>

                                </div>
                                <div className="tab-pane fade" id="primarycontact" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className='maketing'>
                                        <h6>Quảng cáo 1</h6>
                                        <div className="col-3">
                                            {
                                                loadingMarketing1 && <UploadButton idName="marketing1" multiple='true' path='images/' images={marketing1} value={setMarketing1} /> 
                                            }
                                            
                                        </div>
                                        <div className="col-12">
                                        <label>Nhập vào đường dẫn : <input className="form-control"  type={'text'} onChange={(e) => setMarketing1Link(e.target.value)} defaultValue={marketing1Link} /></label>
                                        </div>
                                        <div className="col-12">
                                            <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'marketing1', value : {'images' : marketing1, 'link': marketing1Link}})}>Lưu</button>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className='maketing mt-5'>
                                        <h6>Quảng cáo 2</h6>
                                        <div className="col-3">
                                            { loadingMarketing2 && <UploadButton idName="marketing2" multiple='true' path='images/' images={marketing2} value={setMarketing2} /> }
                                        </div>
                                        <div className="col-12">
                                            <label>Nhập vào đường dẫn : <input className="form-control"  type={'text'} onChange={(e) => setMarketing2Link(e.target.value)} defaultValue={marketing2Link} /></label>
                                        </div>
                                        <div className="col-12">
                                            <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'marketing2', value :{'images' : marketing2, 'link': marketing2Link}})}>Lưu</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="primaryGroup" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Miền Bắc</label>
                                        <textarea className="form-control" id="inputAddress"  defaultValue={mienBac} onChange={(e) => setMienBac(e.target.value)} placeholder="Nhập vào miền bắc..." rows="5"></textarea>
                                        <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'group_mien_bac', value : mienBac}); }}>Lưu</button>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Miền Trung</label>
                                        <textarea className="form-control" id="inputAddress"  defaultValue={mienTrung} onChange={(e) => setMienTrung(e.target.value)} placeholder="Nhập vào miền trung..." rows="5"></textarea>
                                        <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => { updateSetting({key : 'group_mien_trung', value : mienTrung});}}>Lưu</button>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Miền Nam</label>
                                        <textarea className="form-control" id="inputAddress"  defaultValue={mienNam} onChange={(e) => setMienNam(e.target.value)} placeholder="Nhập vào miền nam..." rows="5"></textarea>
                                        <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => { updateSetting({key : 'group_mien_nam', value : mienNam}); }}>Lưu</button>
                                    </div>
                                    
                                </div>
                                <div className="tab-pane fade" id="linkUpdateSchool" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Đường dẫn đăng ký trường</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={linkUpdateSchool} onChange={(e) => setLinkUpdateSchool(e.target.value)} placeholder="Nhập vào đường link đăng ký trường ..."/>
                                    </div>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'link_dang_truong', value : linkUpdateSchool});}}>Lưu</button>
                                </div>
                                <div className="tab-pane fade" id="ExpiryDate" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Thời gian thông báo</label>
                                        <input type='number' style={{width: '250px'}} className="form-control" id="inputAddress"  defaultValue={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} placeholder="Nhập số ngày"/>
                                    </div>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'expiry_date', value : expiryDate});}}>Lưu</button>
                                </div>
                                <div className="tab-pane fade" id="Footer" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Giáo viên</label>
                                        <ReactQuill style={{height: '200px'}} theme="snow" value={footer1} onChange={setFooter1} 
                                            modules={
                                                {
                                                    toolbar: [
                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                    ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link', 'image'],
                                                    ['clean']
                                                    ]
                                                }
                                                
                                            }
                                            
                                            formats={
                                                [
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]
                                            }
                                        />
                                        <button type='button' className='my-1 mt-4 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'giao_vien_footer', value : footer1}); }}>Lưu</button>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Phụ huynh</label>
                                        <ReactQuill style={{height: '200px'}} theme="snow" value={footer2} onChange={setFooter2} 
                                            modules={
                                                {
                                                    toolbar: [
                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                    ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link', 'image'],
                                                    ['clean']
                                                    ]
                                                }
                                                
                                            }
                                            
                                            formats={
                                                [
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]
                                            }
                                        />
                                        <button type='button' className='my-1 mt-4 btn btn-success btn-sm' onClick={() => { updateSetting({key : 'phu_huynh_footer', value : footer2});}}>Lưu</button>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="inputAddress" className="form-label">Chủ trường</label>
                                        <ReactQuill style={{height: '200px'}} theme="snow" value={footer3} onChange={setFooter3} 
                                            modules={
                                                {
                                                    toolbar: [
                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                    ['bold', 'italic', 'underline','strike', 'blockquote'],
                                                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                                    ['link'],
                                                    ['clean']
                                                    ]
                                                }
                                                
                                            }
                                            
                                            formats={
                                                [
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]
                                            }
                                        />
                                        <button type='button' className='my-1 mt-4 btn btn-success btn-sm' onClick={() => { updateSetting({key : 'chu_truong_footer', value : footer3}); }}>Lưu</button>
                                    </div>
                                    
                                </div>
                                
                                <div className="tab-pane fade" id="info" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Địa chỉ</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={address} onChange={(e) => setAddress(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'address', value : address});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Điện thoại</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={phone} onChange={(e) => setPhone(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'phone', value : phone});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Email</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'email', value : email});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Email nhận thông báo</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={emailNotify} onChange={(e) => setEmailNotify(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'email_notify', value : emailNotify});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Facebook</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={facebook} onChange={(e) => setFacebook(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'facebook', value : facebook});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Messenger</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={messenger} onChange={(e) => setMessenger(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'messenger', value : messenger});}}>Lưu</button>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="inputAddress" className="form-label">Zalo</label>
                                        <input type='text' className="form-control" id="inputAddress"  defaultValue={zalo} onChange={(e) => setZalo(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'zalo', value : zalo});}}>Lưu</button>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="post" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-6">
                                        <label htmlFor="post" className="form-label">Chọn trang bài viết</label>
                                        <input type='text' className="form-control" id="post"  defaultValue={post} onChange={(e) => setPost(e.target.value)}/>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'post', value : post});}}>Lưu</button>
                                    </div>
                                </div>
                                
                                <div className="tab-pane fade" id="seoDefault" role="tabpanel" style={{minHeight: '65vh'}}>
                                    <div className="col-6">
                                        {
                                            loadingSeoDefault ? <UploadButton idName="seoDefault" path='images/' images={imageSeoDefault} title='Chọn hình đại diện seo' value={setImageSeoDefault} /> : null
                                        }</div>
                                    
                                    <div className="col-6">
                                        <label htmlFor="post" className="form-label">Tiêu đề seo</label>
                                        <input type='text' className="form-control" defaultValue={titleSeoDefault} onChange={(e) => setTitleSeoDefault(e.target.value)}/>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="post" className="form-label">Nội dung seo</label>
                                        <textarea  className="form-control" defaultValue={contentSeoDefault} onChange={(e) => setContentSeoDefault(e.target.value)} rows="5"></textarea>
                                    </div>
                                    <button type='button' className='my-1 btn btn-success btn-sm' onClick={() => {updateSetting({key : 'seo_default', value : {image: imageSeoDefault, title : titleSeoDefault, content: contentSeoDefault}});}}>Lưu</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>
}
export default View;
import {Link} from 'react-router-dom';
const Breadcrumb = () => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Phân quyền</li>
            </ol>
        </nav>
    );
}

export default Breadcrumb;
import React , { useState, useEffect } from 'react';
import UploadButton from '../../media/upload/UploadButton';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import axios from 'axios';

const View = () => {
    const alert = useAlert();
    const [slider, setSlider] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    // logo
    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'slider_home'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var value = JSON.parse(response.data.value);
            console.log(value);
            setSlider(value);
            setLoading(true);
        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return <div id='slider-cw'>
        <h4>Chọn slider</h4>
        <p>Kích thước khuyên dùng cho slider này là : (1920 X 600)</p>
        {
            loading && <UploadButton idName="image-slider" multiple='true' path='images/slider' images={slider} title='Chọn slider' value={setSlider} />
        }
        <button type='button' className='ms-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'slider_home', value : slider})}>Lưu</button>
                               
    </div>
}
export default View;
import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useAlert } from 'react-alert';

const ConfirmUser = () => {
    const { key } = useParams();
    const [status, setStatus] = useState(false);
    const alert = useAlert();
    
    useEffect(() => {
        fetch(localStorage.getItem('url_api')+'api/auth/confim-user/'+key, {
            method: 'GET'
        })
        .then((response) => response.json())
        .then((response) => {
            if(response.success){
                setStatus(response.success);
            }
            return alert.success(response.message);
        });
    }, []);

    return (
        <div className="card">
            <div className="card-body">
                <div className="border p-4 rounded">
                    <div className="text-center">
                        <h3 className="">XÁC THỰC TÀI KHOẢN</h3>
                        <p>Nếu bạn chưa có tài khoản ? <Link to="/signup">Đăng ký</Link></p>
                    </div>
                    <div className="form-body">
                        {
                            status ? 
                            (
                                <div>
                                    <p className='text-center'>
                                        <i className="bx text-success bx-check-circle bx-lg"></i>  
                                    </p>
                                    <p  className='text-center'>Xác thực thành công. Mời bạn đăng nhập <Link to="/login">Đăng nhập</Link></p>
                                </div>
                            ) : (
                                <div>
                                    <p className='text-center'>
                                        <i className="bx bx-loader bx-spin bx-lg text-info"></i> 
                                    </p>
                                    <p  className='text-center'>Chúng tôi đang xác thực tài khoản của bạn, vui lòng chờ trong giây lát... </p>
                                </div>
                            )
                                
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmUser;
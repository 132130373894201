import React , { useState, useEffect } from 'react';
import UploadButton from '../../media/upload/UploadButton';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import axios from 'axios';

const View = () => {
    const alert = useAlert();
    const [title, setTitle] = useState('');
    const [describe, setDescribe] = useState('');
    const [loading, setLoading] = useState(false);
    const [number1, setNumber1] = useState('');
    const [number2, setNumber2] = useState('');
    const [number3, setNumber3] = useState('');
    const [number4, setNumber4] = useState('');
    const [content1, setContent1] = useState('');
    const [content2, setContent2] = useState('');
    const [content3, setContent3] = useState('');
    const [content4, setContent4] = useState('');

    useEffect(() => {
        getData();
    }, []);

    // logo
    const getData = async () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : 'thongke_home'
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            var value = JSON.parse(response.data.value);
            setTitle(value[0]['title']);
            setDescribe(value[0]['describe']);
            
            setContent1(value[1]['content']);
            setContent2(value[2]['content']);
            setContent3(value[3]['content']);
            setContent4(value[4]['content']);
            
            setNumber1(value[1]['number']);
            setNumber2(value[2]['number']);
            setNumber3(value[3]['number']);
            setNumber4(value[4]['number']);

            setLoading(true);

        }).catch(function (error) {
            setLoading(true);
        });
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return loading && <div id='slider-cw col-5'>
        <div className="col-5 p-2 b-1 border border-danger rounded bg-light"> 
            <label htmlFor="inputAddress" className="form-label">Miêu tả ngắn</label>
            <input type='text' className="form-control" defaultValue={title} onChange={(e) => setTitle(e.target.value)} placeholder=""/>
            <label htmlFor="inputAddress" className="form-label mt-3">Tiêu đề</label>
            <input type='text' className="form-control mt-1" defaultValue={describe} onChange={(e) => setDescribe(e.target.value)} placeholder=""/>
        </div>
        <div className="col-5 p-2 mt-1 b-1 border border-danger rounded bg-light"> 
            <label htmlFor="inputAddress" className="form-label">Số liệu</label>
            <input type='text' className="form-control" defaultValue={number1} onChange={(e) => setNumber1(e.target.value)} placeholder=""/>
            <label htmlFor="inputAddress" className="form-label mt-3">Hiện trạng</label>
            <input type='text' className="form-control mt-1" defaultValue={content1} onChange={(e) => setContent1(e.target.value)} placeholder=""/>
            <hr/>
            <label htmlFor="inputAddress" className="form-label">Số liệu</label>
            <input type='text' className="form-control" defaultValue={number2} onChange={(e) => setNumber2(e.target.value)} placeholder=""/>
            <label htmlFor="inputAddress" className="form-label mt-3">Hiện trạng</label>
            <input type='text' className="form-control mt-1" defaultValue={content2} onChange={(e) => setContent2(e.target.value)} placeholder=""/>
            <hr/>
            <label htmlFor="inputAddress" className="form-label">Số liệu</label>
            <input type='text' className="form-control" defaultValue={number3} onChange={(e) => setNumber3(e.target.value)} placeholder=""/>
            <label htmlFor="inputAddress" className="form-label mt-3">Hiện trạng</label>
            <input type='text' className="form-control mt-1" defaultValue={content3} onChange={(e) => setContent3(e.target.value)} placeholder=""/>
            <hr/>
            <label htmlFor="inputAddress" className="form-label">Số liệu</label>
            <input type='text' className="form-control" defaultValue={number4} onChange={(e) => setNumber4(e.target.value)} placeholder=""/>
            <label htmlFor="inputAddress" className="form-label mt-3">Hiện trạng</label>
            <input type='text' className="form-control mt-1" defaultValue={content4} onChange={(e) => setContent4(e.target.value)} placeholder=""/>
        </div>
        <button type='button' className='mt-1 btn btn-success btn-sm' onClick={() => updateSetting({key : 'thongke_home', value : [
            {'title' : title, 'describe' : describe},
            {'number' : number1, 'content' : content1},
            {'number' : number2, 'content' : content2},
            {'number' : number3, 'content' : content3},
            {'number' : number4, 'content' : content4},
        ]})}>Lưu</button>
                               
    </div>
}
export default View;
import $ from 'jquery';
import validator from 'validator';
import { useAlert } from 'react-alert';
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';

function Login({setAuthToken}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(0);
    const navigate = useNavigate();
    const alert = useAlert()
    
    const  handleLogin = (event) =>  {
        $('#btnLogin').removeClass();
        $('#btnLogin').addClass('bx bx-loader bx-spin bx-md');
        if(validator.isEmpty(email)) {
            $('#btnLogin').removeClass();
            $('#btnLogin').addClass('bx bxs-lock-open');
            return alert.info('Cần nhập vào email');
        }
        if(!validator.isEmail(email)) {
            $('#btnLogin').removeClass();
            $('#btnLogin').addClass('bx bxs-lock-open');
            return alert.info('Địa chỉ Email không chính xác');
        }
        if(validator.isEmpty(password)) {
            $('#btnLogin').removeClass();
            $('#btnLogin').addClass('bx bxs-lock-open');
            return alert.info('Cần nhập vào mật khẩu');
        }

        if(email || password) {
            axios.post(localStorage.getItem('url_api')+'api/auth/login', {email : email, password: password, remember: remember})
            .then(res => {
                var inFifteenMinutes = new Date(new Date().getTime() + parseInt(res.data.token.expires_in) * 60 * 1000 * 24 * 1000000);
                Cookies.set('auth_token_user', res.data.token.access_token, { expires:  inFifteenMinutes });
                Cookies.set('auth_user', JSON.stringify(res.data.user), { expires:  inFifteenMinutes });
                setAuthToken(res.data);
                $('#btnLogin').removeClass();
                $('#btnLogin').addClass('bx bxs-lock-open');
                navigate("/");
                return alert.success('Đăng nhập thành công');
            })
            .catch(result => {    
                $('#btnLogin').removeClass();
                $('#btnLogin').addClass('bx bxs-lock-open');
                for (const [key, value] of Object.entries(result['response']['data'])) {
                    alert.info(value);
                }
            });
            
        }
    }

    const showPassword = (event) => {
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") === "text") {
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass("bx-hide");
            $('#show_hide_password i').removeClass("bx-show");
        } else if ($('#show_hide_password input').attr("type") === "password") {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass("bx-hide");
            $('#show_hide_password i').addClass("bx-show");
        }
    }
    return <div className="card">
        <div className="card-body">
            <div className="border p-4 rounded">
                <div className="text-center">
                    <h3 className="">ĐĂNG NHẬP</h3>
                    <p>Nếu bạn chưa có tài khoản ? <Link to="/signup">Đăng ký</Link>
                    </p>
                </div>
                <div className="form-body">
                    <form className="row g-3" onSubmit={handleLogin} method="POST">
                        <div className="col-12">
                            <label htmlFor="inputEmailAddress" className="form-label">Email</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email} className="form-control" id="inputEmailAddress" placeholder="Nhập email"/>
                        </div>
                        <div className="col-12">
                            <label htmlFor="inputChoosePassword1" className="form-label">Mật khẩu</label>
                            <div className="input-group" id="show_hide_password">
                                <input type="password" className="form-control border-end-0" defaultValue={password} id="inputChoosePassword1"  onChange={(e) => setPassword(e.target.value)} placeholder="Nhập mật khẩu"/> 
                                <a href={this} onClick={showPassword} className="input-group-text bg-transparent"><i className='bx bx-hide'></i></a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-check form-switch">
                                <input className="form-check-input" checked={remember == 1?'checked':''} onChange={(e) => setRemember(remember == 1? 0 : 1)} type="checkbox" id="setRemember" value={1} />
                                <label className="form-check-label" htmlFor="setRemember">Nhớ tài khoản</label>
                            </div>
                        </div>
                        <div className="col-md-6 text-end">	<Link to="/forgot-password">Quên mật khẩu ?</Link>
                        </div>
                        <div className="col-12">
                            <div className="d-grid">
                                <button type="button" onClick={handleLogin} className="btn btn-primary">
                                    <i id="btnLogin" className="bx bxs-lock-open"></i>Đăng nhập
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}
export default Login;
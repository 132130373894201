import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const Rating = () => {
    const [tableData, setTableData] = useState([]);
    const { id } = useParams();
    const [countData, setCountData] = useState(1);
    const [page, setPage] = useState(1);
    const [limit] = useState(25);

    const getData = async (page) => {
        setTableData('');
        axios.post(localStorage.getItem('url_api')+'api/auth/school/rating', {
            page : page,
            id : id,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTableData(response.data.data);
            setCountData(response.data.count);
        });
    }

    const updateData = (d) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/school/rating_actived', {
            id : d.id,
            is_actived : d.is_actived === 1 ? 0 : 1,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }
        });

        getData(page);

    }

    const TableDataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <tr key={i}>
                <td className="text-center align-middle">{i+1}</td>
                <td className='align-middle'>{d.user_name}</td>
                <td className='align-middle text-center '>{d.star}</td>
                <td className='align-middle'>{d.content}</td>
                <td className='align-middle' style={{width: '5%'}} className="text-center">
                    <button type="button" onClick={() => updateData(d)} className={d.is_actived === 1 ? 'btn btn-success btn-sm ml-1' : 'btn btn-primary btn-sm ml-1'} title="Xem" data-bs-toggle="modal" data-bs-target="#btnContact">
                        {d.is_actived === 1 ? <i className="bx bx-show d-content"></i> : <i className="bx bx-hide d-content"></i>}
                    </button>
                </td>
            </tr>
        );

        return content ? content : (
            <tr>
                <td className='text-center' colSpan={7}> 
                    <div className="spinner-border text-danger" role="status"> 
                        <span className="visually-hidden">Loading...</span>
					</div>
                </td>
            </tr>
        );
    }

    useEffect(() => {
        getData(page);
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Đánh giá</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    {/* <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#btnFilter">
                    <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button> */}
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        
                        <div className='row'>
                            <div className='col-6'>
                                <h6 className="mb-0 text-uppercase">Đánh giá</h6>
                            </div>
                            <div className='col-6 text-end'>
                                <span className="mb-0">Trang hiện thời là {page}/{Math.ceil(countData / limit)}. Có tổng {countData} liên hệ</span>
                            </div>
                        </div>
                        <hr/>

                        <div className="card">
                            <div className="card-body">
                                <table id="example" className="table table-striped table-bordered" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th className="text-center align-middle" style={{width: '2%'}}>STT</th>
                                            <th className="text-center align-middle" style={{width: '20%'}}>Họ và tên</th>
                                            <th className="text-center align-middle">Số sao</th>
                                            <th className="text-center align-middle">Chi tiết</th>
                                            <th className="text-center align-middle">Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData ? <TableDataBody tableData={tableData}/> :  <tr>
                                                <td className='text-center' colSpan={7}> 
                                                    <div className="spinner-border spinner-border-sm text-danger" role="status"> 
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th className="text-center align-middle">STT</th>
                                            <th className="text-center align-middle">Họ và tên</th>
                                            <th className="text-center align-middle">Số sao</th>
                                            <th className="text-center align-middle">Chi tiết</th>
                                            <th className="text-center align-middle">Cập nhật</th>
                                        </tr>
                                    </tfoot>
                                </table>

                                <PaginationControl
                                    page={page}
                                    between={4}
                                    total={countData}
                                    limit={limit}
                                    changePage={(page) => {
                                        setPage(page); 
                                        getData(page);
                                        window.scrollTo(0,0);
                                    }}
                                    ellipsis={1}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Rating;
import React , { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
// import validator from 'validator';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { useAlert } from 'react-alert';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const View = () => {
    const alert = useAlert();
    const [tableData, setTableData] = useState([]);
    const [countData, setCountData] = useState(1);
    const [categoryData, setCategoryData] = useState([]);
    const [typeTime, setTypeTime] = useState([]);
    const [schoolTypeData, setSchoolTypeData] = useState([]);
    const [schoolNoteData, setSchoolNoteData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [isActived, setIsActived] = useState(1);
    const [limit, setLimit] = useState(25);
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [slug, setSlug] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [typeSchool, setTypeSchool] = useState('');
    const [city, setCity] = useState('');
    const [note, setNote] = useState('');
    const [district, setDistrict] = useState('');
    const [idRemove, setIdRemove] = useState('');
    const [idNote, setIdNote] = useState('');
    const [content, setContent] = useState('');
    const [potential, setPotential] = useState('');
    const [urlApi] = useState(localStorage.getItem('url_api'));
    let [page, setPage] = useState(1);

    const getData = async (page) => {
        setTableData('');
        axios.post(urlApi+'api/auth/school/view', {
            name_category : name,
            slug_category : slug,
            expiry_date : expiryDate,
            city : city,
            district : district,
            id_school_category : category,
            page : page,
            is_actived : isActived,
            note : note,
            type_school : typeSchool,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setTableData(response.data.data);
            setCountData(response.data.count);
            setTypeTime(response.data.type_time);
            setCategoryData(response.data.category);
            setSchoolTypeData(response.data.school_type);
            setCityData(response.data.city);
        });
    }

    const reSet = async () => {
        setName('');
        setSlug('');
        setCity('');
        setNote('');
        setDistrict('');
        setCategory('');
        setExpiryDate('');
        setIsActived('');
        setLimit(25);
        setTypeSchool('');
        document.getElementById("filter-form").reset();
    }

    const TableDataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <tr key={i}>
                <td className='text-center align-middle' style={{width: '0.5%'}}>{i+1}</td>
                <td style={{width: '5%'}} className='align-middle'>
                    <i style={{fontSize: '14px'}} className={d.is_confirm === 1 ? 'bx bx-badge-check text-success' : ''}></i>
                    <a href={ d.subdomain ? 'https://'+d.subdomain+'.kidhub.vn' : urlApi+d.slug_school+'/thong-tin-truong' } target="_blank">{d.name_school}</a>
                </td>
                <td className='text-center align-middle'>{d.name_school_category}</td>
                <td className={d.expiry_date_status === 1 ? 'text-center bg-warning align-middle' : 'text-center align-middle'} >{d.expiry_date}</td>
                {/* <td className='text-center align-middle'>{'Từ '+ d.age_begin + ' '+ typeTime[d.age_begin_type] + ' đến ' + d.age_end + ' '+ typeTime[d.age_end_type]}</td> */}
                {/* <td className='text-center align-middle'>{(d.tuition_begin && d.tuition_end) && 'Từ '+d.tuition_begin.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) + ' đến ' + d.tuition_end.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}) + ' / ' + typeTime[d.tuition_type]}</td> */}
                <td className='text-center align-middle'>{d.full_address}</td>
                <td className='text-center align-middle'>{d.phone}</td>
                <td className='text-center align-middle'>{d.note}</td>
                <td className="text-center font-22 align-middle"><i className={ d.is_actived === 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                <td style={{width: '5%'}} className="text-center align-middle">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={'/school/content/'+ d.id} className="btn btn-warning ml-1 btn-sm" title="Sửa"><i className="bx bx-list-plus text-white"></i></Link>
                        <Link to={'/school/rating/'+ d.id} className="btn btn-info ml-1 btn-sm" title="Sửa"><i className="bx bx-star text-white"></i></Link>
                        <Link to={'/school/edit/'+ d.id} className="btn btn-success ml-1 btn-sm" title="Sửa"><i className="bx bx-edit"></i></Link>
                        <button type="button" onClick={() => loadingNote(d.id)} className="btn btn-secondary btn-sm ml-1" title="Ghi lại quá trình tư vấn" data-bs-toggle="modal" data-bs-target="#myModalNoteRight"><i className="bx bx-note"></i></button>
                        <button type="button" onClick={() => setIdRemove(d.id)} className="btn btn-primary btn-sm ml-1" title="Xóa" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );

        return content;
    }

    const loadingNote = (id) => {
        if(id === '') {
            return alert.info('Thông tin trường không hợp lệ');
        } else {
            setIdNote(id);
        }

        axios.post(urlApi+'api/auth/school/view_note', {
            id : id,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setSchoolNoteData(response.data.data);
            if(response.data.success === true) {
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        });

    }

    const handleRemove = () => {
        if(idRemove == null) {
           return alert.error('Cần nhập vào danh mục bạn cần xóa');
        }

        axios.post(urlApi+'api/auth/school/remove', {
            id : idRemove,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData(page);
            if(response.data.success === true) {
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        })
    }

    const getDistrict = (parent_id) => {
        if(parent_id == null) {
            return 0;
        }
        setCity(parent_id); 

        axios.post(localStorage.getItem('url_api')+'api/auth/address/get', {
            parent_id: parent_id,
            type: 'district'
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setDistrictData(response.data.local);
        });
    }

    const updateSchoolNote = () => {
        axios.post(urlApi+'api/auth/school/add_note', {
            school_id : idNote,
            potential : potential,
            content : content
        },
        {
            headers: {
            'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
            'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            if(response.data.success === true) {
                loadingNote(idNote);
                noteRefresh();
                return alert.success(response.data.message);
            } else {
                return alert.info(response.data.message);
            }
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        });
    }

    const noteRefresh = () => {
        setIdNote('');
        setPotential('');
        setContent('');
        document.getElementById("create-course-form").reset();
    }

    useEffect(() => {
        getData(page);
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Danh mục</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#btnFilter">
                    <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button>
                    <Link to="/school/add" className="mx-1 btn btn-success btn-sm">
                    <i className="bx bx-add-to-queue fa-sm text-white"></i>Thêm</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className="mb-0 text-uppercase">Danh mục</h6>
                        </div>
                        <div className='col-6 text-end'>
                            <span className="mb-0">Trang hiện thời là {page}/{Math.ceil(countData / limit)}. Có tổng {countData} danh mục</span>
                        </div>
                    </div>
                    <hr/>

                    <div className="card">
                        <div className="card-body">
                        {/* table-responsive */}
                            <div className="table-responsive-custom">
                                <table id="example" className="table table-striped table-bordered" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th className="text-center align-middle" style={{width: '2%'}}>STT</th>
                                            <th className="text-center align-middle" style={{width: '20%'}}>Tên trường</th>
                                            <th className="text-center align-middle"  style={{width: '10%'}}>Tên danh mục</th>
                                            <th className="text-center align-middle"  style={{width: '10%'}}>Thời hạn</th>
                                            {/* <th className="text-center align-middle">Nhận trẻ</th>
                                            <th className="text-center align-middle">Học phí</th> */}
                                            <th className="text-center align-middle">Địa chỉ</th>
                                            <th className="text-center align-middle">Điện thoại</th>
                                            <th className="text-center align-middle">Tìm năng</th>
                                            <th className="text-center font-22 align-middle" style={{width: '5%'}}>
                                                <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                <i className='text-warning bx bx-checkbox'></i>
                                            </th>
                                            <th className="text-center align-middle">Cập nhật</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            
                                        {
                                            tableData ? <TableDataBody tableData={tableData}/> :  <tr>
                                                <td className='text-center' colSpan={9}> 
                                                    <div className="spinner-border spinner-border-sm text-danger" role="status"> 
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th className="text-center align-middle" style={{width: '2%'}}>STT</th>
                                            <th className="text-center align-middle" style={{width: '5%'}}>Tên trường</th>
                                            <th className="text-center align-middle"  style={{width: '5%'}}>Tên danh mục</th>
                                            <th className="text-center align-middle"  style={{width: '5%'}}>Thời hạn</th>
                                            {/* <th className="text-center align-middle">Nhận trẻ</th>
                                            <th className="text-center align-middle">Học phí</th> */}
                                            <th className="text-center align-middle">Địa chỉ</th>
                                            <th className="text-center align-middle">Điện thoại</th>
                                            <th className="text-center align-middle">Tìm năng</th>
                                            <th className="text-center font-22 align-middle" style={{width: '5%'}}>
                                                <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                <i className='text-warning bx bx-checkbox'></i>
                                            </th>
                                            <th className="text-center align-middle">Cập nhật</th>
                                        </tr>
                                    </tfoot>
                                </table>

                                <PaginationControl
                                    page={page}
                                    between={4}
                                    total={countData}
                                    limit={limit}
                                    changePage={(page) => {
                                        setPage(page); 
                                        getData(page);
                                        window.scrollTo(0,0);
                                    }}
                                    ellipsis={1}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* tìm kiếm */}
            <div className="modal fade" id="btnFilter" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title text-sm">Lọc</h4>
                            <button type="button"  className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form method='POST' id="filter-form">
                            <div className='row'>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="usr">Tên trường: <b className="text-danger"></b></label>
                                    <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" className="form-control"/>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Tình trạng: <b className="text-danger"></b></label>
                                    <select className="form-control" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)}>
                                        <option value={''}>== Tất cả ==</option>
                                        <option value={'tra-phi'}>Trả phí</option>
                                        <option value={'mien-phi'}>Miễn phí</option>
                                    </select>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Loại trường: <b className="text-danger"></b></label>
                                    <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                                        <option value={''}>== Chọn loại trường ==</option>
                                        {
                                            Object.keys(categoryData).map((k, v) => <option key={v} value={k}>{categoryData[k]}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Hệ trường: <b className="text-danger"></b></label>
                                    <select className="form-control" value={typeSchool} onChange={(e) => setTypeSchool(e.target.value)}>
                                        <option value={''}>== Chọn hệ trường ==</option>
                                        {
                                            Object.keys(schoolTypeData).map((k, v) => <option key={v} value={k}>{schoolTypeData[k]}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Trang thái: <b className="text-danger"></b></label>
                                    <select className="form-control" value={isActived} onChange={(e) => setIsActived(e.target.value)}>
                                        <option value={''}>== Trạng thái ==</option>
                                        <option value={0}>Đã ẩn</option>
                                        <option value={1}>Đang hoạt động</option>
                                    </select>
                                </div><div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Tìm năng: <b className="text-danger"></b></label>
                                    <select className="form-control" value={note} onChange={(e) => setNote(e.target.value)}>
                                        <option value={''}>== Sắp xếp ==</option>
                                        <option value={'tang-dan'}>Tăng dần</option>
                                        <option value={'giam-dan'}>Giảm dần</option>
                                    </select>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Tỉnh thành: <b className="text-danger"></b></label>
                                    <select className="form-control" value={city} onChange={(e) => getDistrict(e.target.value)}>
                                        <option value={''}>== Chọn tỉnh thành ==</option>
                                        {
                                            cityData && cityData.map((k, v) => <option key={v} value={k.id}>{k.full_address}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group input-group-sm col-md-6 mt-3">
                                    <label htmlFor="pwd">Quận huyện: <b className="text-danger"></b></label>
                                    <select className="form-control" value={district} onChange={(e) => setDistrict(e.target.value)}>
                                        <option value={''}>== Chọn quận huyện ==</option>
                                        {
                                           districtData && districtData.map((k, v) => <option key={v} value={k.id}>{k.full_address}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => reSet()} className="btn btn-primary btn-sm">Xóa</button>
                        <button type="button" onClick={() => {setPage(1); getData(1)}} className="btn btn-success btn-sm" data-bs-dismiss="modal">Lọc</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* xóa bỏ đổi tượng */}
            <div className="modal fade" id="myModalRemove" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Xác nhận xóa bỏ</h4>
                            <button type="button" onClick={() => setIdRemove('')} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> trường học này không ?</p>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => setIdRemove('')} className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Hủy</button>
                        <button type="button" onClick={() => handleRemove()} className="btn btn-primary btn-sm" data-bs-dismiss="modal">Xóa</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* Ghi lại thông tin quá trình tư vấn */}
            <div className="modal fade" id="myModalNoteRight" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Thông tin tư vấn khách hàng</h4>
                                <button type="button" onClick={() => noteRefresh()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                        </div>

                        <div className="modal-body">
                            <div className='loading-note-content'>
                                {
                                    schoolNoteData && schoolNoteData.map((k, v) => 
                                    <div key={v} className='loading-note-item'>
                                        <h6>{k.date}</h6>
                                        <div className='note-school'>{k.potential}</div>
                                        <p>{k.content}</p>
                                    </div>)
                                }
                            </div>
                            <hr/>
                            <div className='form-note-content'>
                                <form className='row' id='create-course-form'>
                                    <div className="mb-3 mt-2">
                                        <label htmlFor="comment">Nhập nội dung cần ghi lại:</label>
                                        <textarea onChange={(e) => setContent(e.target.value)} defaultValue={content} className="form-control" rows="3" ></textarea>
                                        <div className="form-group">
                                            <label htmlFor="pwd">Tìm năng: <b className="text-danger"></b></label>
                                            <select className="form-control" value={potential} onChange={(e) => setPotential(e.target.value)}>
                                                <option value={''}>== Tất cả ==</option>
                                                <option value={'1'}>1</option>
                                                <option value={'2'}>2</option>
                                                <option value={'3'}>3</option>
                                                <option value={'4'}>4</option>
                                                <option value={'5'}>5</option>
                                            </select>
                                        </div>
                                        <button type="button" onClick={() => updateSchoolNote()} className="btn btn-primary mt-1">Ghi chú</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
	

        </div>
    );
}

export default View;
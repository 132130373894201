import Header from './components/header';
import Footer from './components/footer';
import Sidebar from './components/sidebar';
import { Outlet } from "react-router-dom";

const App = ({setAuthToken}) => {
  return (
    <div className="wrapper">
        <Sidebar />
        <Header />
        <div className="page-wrapper">
            <Outlet />
        </div>
        <Footer />
    </div>
  )
};

export default App;
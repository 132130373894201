import React , { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import axios from 'axios';
// import validator from 'validator';
import UploadButton from '../media/upload/UploadButton';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useAlert } from 'react-alert';

const View = () => {
    const alert = useAlert();
    const [tableData, setTableData] = useState([]);
    const [dataFull, setDataFull] = useState([]);
    const [countData, setCountData] = useState(1);
    const [termData, setTermData] = useState([]);
    const [isActived, setIsActived] = useState('');
    const [limit, setLimit] = useState(25);
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [slug, setSlug] = useState('');
    const [type, setType] = useState('');
    const [nameAdd, setNameAdd] = useState('');
    const [slugAdd, setSlugAdd] = useState('');
    const [typeAdd, setTypeAdd] = useState('');
    const [isActivedAdd, setIsActivedAdd] = useState(1);
    const [parentIDAdd, setParentIDAdd] = useState(0);
    const [idRemove, setIdRemove] = useState('');
    const [page, setPage] = useState(1);

    const slugify = (str) => {
        // Chuyển hết sang chữ thường
        str = str.toLowerCase();     
        
        // xóa dấu
        str = str
            .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
            .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp
        
        // Thay ký tự đĐ
        str = str.replace(/[đĐ]/g, 'd');
        
        // Xóa ký tự đặc biệt
        str = str.replace(/([^0-9a-z-\s])/g, '');
        
        // Xóa khoảng trắng thay bằng ký tự -
        str = str.replace(/(\s+)/g, '-');
        
        // Xóa ký tự - liên tiếp
        str = str.replace(/-+/g, '-');
        
        // xóa phần dư - ở đầu & cuối
        str = str.replace(/^-+|-+$/g, '');
        
        // return
        return str;
    }

    const getData = async (page) => {
        setTableData('');
        axios.post(localStorage.getItem('url_api')+'api/auth/category/view', {
            name_category : name,
            slug_category : slug,
            type : type,
            page : page,
            is_actived : isActived,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setDataFull(response.data.data_full);
            setTableData(response.data.data);
            setCountData(response.data.count);
            setTermData(response.data.term);
        });
    }

    const reSet = async () => {
        setName('');
        setSlug('');
        setType('');
        setIsActived('');
        setLimit(25);
        setImage('');
        setNameAdd('');
        setSlugAdd('');
        setTypeAdd('');
        setIsActivedAdd('');
        document.getElementById("filter-form").reset();
    }
   
    const updateData = () => {
        if(image === '') {
            setImage('question-svgrepo-com.svg');
        }

        if(typeAdd === '') {
            return alert.info('Bạn cần chọn nhóm');
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/category/add', {
            name_category : nameAdd,
            slug_category : slugAdd,
            type : typeAdd,
            image : image,
            is_actived : isActivedAdd,
            parent_id : parentIDAdd,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData(page);
            return alert.success(response.data.message);
        })
    }

    const onClickClose = () => {
        setIdRemove('');
    }

    const handleDelete = () => {
        if(idRemove == null) {
           return alert.error('Cần nhập vào danh mục bạn cần xóa');
        }

        axios.post(localStorage.getItem('url_api')+'api/auth/category/remove', {
            id : idRemove,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            getData(page);
            return alert.success(response.data.message);
        })
        .error(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            return alert.info(response.data.message);
        })
    }

    const TableDataBody = (props) => {
        
        const content = props.tableData.map((d, i) =>
            <tr key={i}>
                <td className="text-center align-middle">{i+1}</td>
                <td className="text-center align-middle"><img width={50} src={localStorage.getItem('url_api')+'assets/images/svg/'+d.thumbnails}/></td>
                <td className='align-middle'>{d.name_school_category}</td>
                <td className='align-middle'>{d.slug_school_category}</td>
                <td className='align-middle'>{termData[d.type]}</td>
                <td className="text-center align-middle font-22"><i className={ d.is_actived == 1 ? 'text-success bx bx-checkbox-checked': 'text-warning bx bx-checkbox'}></i></td>
                <td style={{width: '5%'}} className="text-center align-middle">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <Link to={'/category/edit/'+(d.id)} className="btn btn-success ml-1 btn-sm" title="Sửa"><i className="bx bx-edit"></i></Link>
                        <button type="button" onClick={() => setIdRemove(d.id)} className="btn btn-primary btn-sm ml-1" title="Xóa" data-bs-toggle="modal" data-bs-target="#myModalRemove"><i className="bx bx-trash"></i></button>
                    </div>
                </td>
            </tr>
        );

        return content ? content : (
            <tr>
                <td className='text-center' colSpan={7}> 
                    <div className="spinner-border text-danger" role="status"> 
                        <span className="visually-hidden">Loading...</span>
					</div>
                </td>
            </tr>
        );
    }

    const SelectOption = (props) => {
        const content = Object.keys(props.termData).map((t, i) =>
            <option key={i} value={t}>{termData[t]}</option>
        );

        return content;
    }

    const SelectOptionFull = (props) => {
        const content = Object.keys(props.dataFull).map((d, i) => 
                props.dataFull[d]['type'] === 'post' ? <option key={i} value={props.dataFull[d]['id']}>{props.dataFull[d]['name_school_category']}</option> : null
        );

        return content ? content : <option value=''>Đang tải ...</option>;
    }

    useEffect(() => {
        getData(page);
        console.log(termData);
    }, []);

    return (
		<div className="page-content">
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><Link to={'/'}><i className="bx bx-home-alt"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Danh mục</li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#btnFilter">
                    <i className="bx bx-filter-alt fa-sm text-white"></i>Lọc</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mx-auto">
                    <div className='row'>
                        
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h6 className="mb-0 text-uppercase">Danh mục</h6>
                                </div>
                                <div className='col-6 text-end'>
                                    <span className="mb-0">Trang hiện thời là {page}/{Math.ceil(countData / limit)}. Có tổng {countData} danh mục</span>
                                </div>
                            </div>
                            <hr/>

                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table id="example" className="table table-striped table-bordered" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center align-middle" style={{width: '2%'}}>STT</th>
                                                    <th className="text-center align-middle">Hình ảnh</th>
                                                    <th className="text-center align-middle">Tên danh mục</th>
                                                    <th className="text-center align-middle">Đường dẫn</th>
                                                    <th className="text-center align-middle">Loại</th>
                                                    <th className="text-center align-middle font-22">
                                                        <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                        <i className='text-warning bx bx-checkbox'></i>
                                                    </th>
                                                    <th className="text-center align-middle">Cập nhật</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData ? <TableDataBody tableData={tableData}/> :  <tr>
                                                        <td className='text-center' colSpan={7}> 
                                                            <div className="spinner-border spinner-border-sm text-danger" role="status"> 
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th className="text-center align-middle">STT</th>
                                                    <th className="text-center align-middle">Hình ảnh</th>
                                                    <th className="text-center align-middle">Tên danh mục</th>
                                                    <th className="text-center align-middle">Đường dẫn</th>
                                                    <th className="text-center align-middle">Loại</th>
                                                    <th className="text-center align-middle font-22">
                                                        <i className='text-success bx bx-checkbox-checked'></i> &nbsp;
                                                        <i className='text-warning bx bx-checkbox'></i>
                                                    </th>
                                                    <th className="text-center align-middle">Cập nhật</th>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <PaginationControl
                                            page={page}
                                            between={4}
                                            total={countData}
                                            limit={limit}
                                            changePage={(page) => {
                                                setPage(page); 
                                                getData(page);
                                                window.scrollTo(0,0);
                                            }}
                                            ellipsis={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>

                            <div className='row'>
                                <div className='col-12'>
                                    <h6 className="mb-0 text-uppercase">Thêm danh mục</h6>
                                </div>
                            </div>
                            <hr/>
                            
                            <div className="card">
                                <div className="card-body">
                                    <div className='p-4 border rounded'>
                                        <form method='POST' id="filter-form">
                                            <div className="form-group input-group-sm">
                                                <label htmlFor="usr">Tên danh mục (*): <b className="text-danger"></b></label>
                                                <input onChange={(e) => {setNameAdd(e.target.value); setSlugAdd(slugify(e.target.value))}} defaultValue={nameAdd} type="text" className="form-control"/>
                                            </div>
                                            <div className="form-group input-group-sm">
                                                <label htmlFor="pwd">Đường dẫn: <b className="text-danger"></b></label>
                                                <input onChange={(e) => setSlugAdd(e.target.value)} defaultValue={slugAdd} type="text" className="form-control"/>
                                            </div>
                                            <div className="form-group input-group-sm">
                                                <label htmlFor="pwd">Nhóm  (*): <b className="text-danger"></b></label>
                                                <select className="form-control" value={typeAdd} onChange={(e) => setTypeAdd(e.target.value)}>
                                                    <option value={''}>== Chọn nhóm danh mục ==</option>
                                                    <SelectOption termData={termData}/>
                                                </select>
                                            </div>
                                            {
                                                (typeAdd === 'post') ?
                                                <div className="form-group input-group-sm">
                                                    <label htmlFor="pwd">Danh mục cha: <b className="text-danger"></b></label>
                                                    <select className="form-control" value={parentIDAdd} onChange={(e) => setParentIDAdd(e.target.value)}>
                                                        <option value={''}>== Chọn loại danh mục cha ==</option>
                                                        <SelectOptionFull dataFull={dataFull} char=''/>
                                                    </select>
                                                </div>
                                                : null
                                            }
                                            
                                            <div className="form-group input-group-sm">
                                                <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                                <select className="form-control" value={isActivedAdd} onChange={(e) => setIsActivedAdd(e.target.value)}>
                                                    <option value={''}>== Trạng thái ==</option>
                                                    <option value={0}>Đã ẩn</option>
                                                    <option value={1}>Đang hoạt động</option>
                                                </select>
                                            </div>
                                            <div className="form-group input-group-sm m-1">
                                                <UploadButton idName="image-category" path='assets/images/svg' images={[image]} title='Chọn hình' value={setImage} /> 
                                            </div>
                                            <button type="button" onClick={() => {updateData()}} className='btn btn-success'>Lưu</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                </div>
            </div>

            {/* form tìm kiếm */}
            <div className="modal fade" id="btnFilter" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title text-sm">Lọc</h4>
                            <button type="button"  className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <form method='POST' id="filter-form">
                            <div className="form-group input-group-sm">
                                <label htmlFor="usr">Tên danh mục: <b className="text-danger"></b></label>
                                <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" className="form-control"/>
                            </div>
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Đường dẫn: <b className="text-danger"></b></label>
                                <input onChange={(e) => setSlug(e.target.value)} defaultValue={slug} type="text" className="form-control"/>
                            </div>
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value={''}>== Chọn loại danh mục ==</option>
                                    <SelectOption termData={termData}/>
                                </select>
                            </div>
                            <div className="form-group input-group-sm">
                                <label htmlFor="pwd">Loại: <b className="text-danger"></b></label>
                                <select className="form-control" value={isActived} onChange={(e) => setIsActived(e.target.value)}>
                                    <option value={''}>== Trạng thái ==</option>
                                    <option value={0}>Đã ẩn</option>
                                    <option value={1}>Đang hoạt động</option>
                                </select>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => reSet()} className="btn btn-primary btn-sm">Xóa</button>
                        <button type="button" onClick={() => { setPage(1); getData(1)}} className="btn btn-success btn-sm" data-bs-dismiss="modal">Lọc</button>
                    </div>

                    </div>
                </div>
            </div>

            {/* xóa bỏ đổi tượng */}
            <div className="modal fade" id="myModalRemove" data-bs-backdrop="static">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Xác nhận xóa bỏ</h4>
                            <button type="button" onClick={() => onClickClose()} className="btn-close" data-bs-dismiss="modal" aria-label="Đóng"></button>
                    </div>

                    <div className="modal-body">
                        <p className="text-center">Bạn có chắc là muốn <b className="text-danger">xóa</b> danh mục này không ?</p>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={() => onClickClose()} className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Hủy</button>
                        <button type="button" onClick={() => handleDelete()} className="btn btn-primary btn-sm" data-bs-dismiss="modal">Xóa</button>
                    </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default View;
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useAlert } from 'react-alert';

const View = () => {
    const [category, setCategory] = useState([]);
    const alert = useAlert();
    const [formValues, setFormValues] = useState([{ id: '' }]);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues]; 
        let value = JSON.parse(e.target[e.target.selectedIndex].getAttribute('valueJson'));
        newFormValues[i]['name'] = value.name_school_category;
        newFormValues[i]['slug'] = value.slug_school_category;
        newFormValues[i]['image'] = value.thumbnails;
        newFormValues[i]['id'] = value.id;
        setFormValues(newFormValues);
      }
    
    let addFormFields = () => {
        setFormValues([...formValues, { id: '' }])
      }
    
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    useEffect(() => {
        getCategory();
        getData('category_home');
    }, []);
    
    const getCategory = async (key) => {
        // danh mục
        axios.post(localStorage.getItem('url_api')+'api/auth/category/view', {
            limmit: 80,
            type: 'post'
        },
        {
            headers: {
                'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setCategory(response.data.data);
        });
    }

    const getData = async (key) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/get_setting', {
            key : key
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            if(response.data.success) {
                var value = JSON.parse(response.data.value);
                setFormValues(value);
            }
        });
        
    }

    const updateSetting = async (data) => {
        axios.post(localStorage.getItem('url_api')+'api/auth/setting/update_setting', data,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            alert.success(response.data.message);
        })
        .catch(function (error) {
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            alert.info(error.data.message);
        });
    }

    return (
        <form id='school-custom-home' onSubmit={updateSetting}>
            <div className='row'>
                {formValues.map((element, index) => (
                    <div key={index} className='col-3 mt-3'>
                       <div className="input-group">
                            <select className="form-select" value={element.id || ""} name='id' onChange={e => {handleChange(index, e);}}>
                                <option>== Chọn trương, trung tâm ==</option>
                                {
                                    category.map((k, v) => <option valueJson={JSON.stringify(k)} value={k.id}>{k.name_school_category}</option>)
                                }
                            </select>
                            <button type="button"  className="button remove btn btn-sm btn-dark" onClick={() => removeFormFields(index)}><i className='bx bx-trash'></i></button> 
                        </div>
                    </div>
                ))}
            </div>     
            <div className="button-section mt-3 col-12">
                <button className="mt-1 button add btn btn-sm btn-dark" type="button" onClick={() => addFormFields()}>Thêm</button>
                <button type='button' className='mt-1 btn btn-success btn-sm  float-right' onClick={() => updateSetting({key : 'category_home', value : formValues})}>Lưu</button>
            </div>
      </form>
    )
}

export default View;
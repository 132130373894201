import React , { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import { useAlert } from 'react-alert';

function UserProfile() {
    const alert = useAlert();
    const { id } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isActived, setIsActived] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState([]);
    const [roleUser, setRoleUser] = useState('');
    const [roleUserCurrent, setRoleUserCurent] = useState('');

    const getData = async () => {
        axios.get(localStorage.getItem('url_api')+'api/auth/user/detail/'+id,
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            setFirstName(response.data.user.first_name);
            setLastName(response.data.user.last_name);
            setEmail(response.data.user.email);
            setPhone(response.data.user.phone);
            setIsActived(response.data.user.is_actived);
            setRole(response.data.role);
            response.data.role_user && setRoleUser(response.data.role_user.role_key);
            response.data.role_user_curent && setRoleUserCurent(response.data.role_user_curent.role_key);
        });
    }

    const editUser = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/user/edit', {
            id : id,
            first_name : firstName,
            last_name : lastName,
            phone : phone,
            email : email,
            is_actived : isActived,
            password : password,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            if(response.data.success === true) {
                alert.success(response.data.message);
            } else {
                alert.info(response.data.message);
            }
        });
    }

    

    const editUserRole = () => {
        axios.post(localStorage.getItem('url_api')+'api/auth/role/edit_user', {
            'id' : id,
            'role_key' : roleUser,
        },
        {
            headers: {
              'Authorization': `Bearer ${Cookies.get('auth_token_user')}`,
              'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.constructor === String) {
                response = JSON.parse(response);
            }

            if(response.data.success === true) {
                alert.success(response.data.message);
            } else {
                alert.info(response.data.message);
            }
        }).catch(function (error) {
           
            if (error.constructor === String) {
                error = JSON.parse(error);
            }

            var arrError = error.response.data;
            if (arrError.constructor === String) {
                arrError = JSON.parse(arrError);
            }

            for (const key in arrError) {
                if (Object.hasOwnProperty.call(arrError, key)) {
                    const element = arrError[key];
                    for (let index = 0; index < element.length; index++) {
                        const e = element[index];
                        alert.info(e);
                    }
                }
            }

        });
    }

    useEffect(() => {
        getData();
    }, []);

    return <div className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Tài khoản</div>
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item"><a href={this}><i className="bx bx-home-alt"></i></a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Thông tin tài khoản</li>
                    </ol>
                </nav>
            </div>
            <div className="ms-auto">
                {/* <div className="btn-group">
                    <button type="button" className="btn btn-primary">
                        <i className="bx bx-cog"></i>
                    </button>
                    <button type="button" className="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span className="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	
                    <a className="dropdown-item" href={this}>Action</a>
                        <a className="dropdown-item" href={this}>Another action</a>
                        <a className="dropdown-item" href={this}>Something else here</a>
                        <div className="dropdown-divider"></div>	<a className="dropdown-item" href={this}>Separated link</a>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="container">
            <div className="main-body">
                <div className="row">
                    {/* <div className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src={require('../../../assets/images/avatars/avatar-2.png')} alt="Admin" className="rounded-circle p-1 bg-primary" width="110"/>
                                    <div className="mt-3">
                                        <h4>Phương Nguyễn</h4>
                                        <p className="text-secondary mb-1">Software</p>
                                        <button className="btn btn-outline-primary">Gửi tin nhắn</button>
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-globe me-2 icon-inline"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>Website</h6>
                                        <span className="text-secondary">https://codervent.com</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-github me-2 icon-inline"><path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path></svg>Github</h6>
                                        <span className="text-secondary">codervent</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-twitter me-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
                                        <span className="text-secondary">@codervent</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram me-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
                                        <span className="text-secondary">codervent</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-facebook me-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
                                        <span className="text-secondary">codervent</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-8 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Họ</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input type="text" className="form-control"  defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}  />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Tên</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input type="text" className="form-control" defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Email</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input type="text" className="form-control" defaultValue={email} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Điện thoại</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input type="text" className="form-control" defaultValue={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Mật khẩu</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input type="password" className="form-control" defaultValue={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                    <input type="checkbox" checked={isActived} className="form-check-input" id='isActived' onChange={() => setIsActived(isActived === 1 ? 0: 1)} /> &nbsp;
                                    <label htmlFor='isActived'>Tài khoản đang hoạt động</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-9 text-secondary">
                                        <button type="button" onClick={() => editUser()} className="btn btn-primary btn-sm px-4" >Lưu {roleUserCurrent}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            roleUserCurrent === 'admin' && <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Thêm quyền</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            <select className="form-select" onChange={(e) => {setRoleUser(e.target.value)}}  defaultValue={roleUser}>
                                                <option value={''}>== Chọn phân quyền == </option>
                                                {
                                                   role && role.map((u, i) => 
                                                        <option selected={roleUser === u.value ? true: false} key={i} value={u.value}>{u.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-9 text-secondary">
                                        <button type="button" onClick={() => editUserRole()} className="btn btn-primary btn-sm px-4" >Lưu</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="d-flex align-items-center mb-3">Sản phẩm của bạn</h5>
                                        <p>Web Design</p>
                                        <div className="progress mb-3" style={{height: "5px"}}>
                                            <div className="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>Website Markup</p>
                                        <div className="progress mb-3" style={{height: "5px"}}>
                                            <div className="progress-bar bg-danger" role="progressbar" style={{width: "72%"}} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>One Page</p>
                                        <div className="progress mb-3" style={{height: "5px"}}>
                                            <div className="progress-bar bg-success" role="progressbar" style={{width: "89%"}} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>Mobile Template</p>
                                        <div className="progress mb-3" style={{height: "5px"}}>
                                            <div className="progress-bar bg-warning" role="progressbar" style={{width: "55%"}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>Backend API</p>
                                        <div className="progress" style={{height: "5px"}}>
                                            <div className="progress-bar bg-info" role="progressbar" style={{width: "66%"}} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default UserProfile;